import { postCalltoAPI } from "../axiosCall";


export async function createUser(sendData) {
    
    var response = await postCalltoAPI( "/create_user", sendData);
    return response;
}


export async function updateUser(sendData) {
    
    var response = await postCalltoAPI( "/update_user", sendData);
    return response;
}