import { 
    Container,
    Grid,
} from "@cloudscape-design/components";
import BoxInfo from "../customs/boxInfo";


export default function AccountInfoCard({ name, clientType, compteType, isAuthorized}) {

    return (
    <Container>
        <Grid
            gridDefinition={[
                {colspan: {default: 12, xs: 3}},
                {colspan: {default: 12, xs: 3}},
                {colspan: {default: 12, xs: 3}},
                {colspan: {default: 12, xs: 3}},
            ]}
        >
            <BoxInfo title="Nom du Compte" value={name} />
            <BoxInfo title="Type de Client" value={ clientType.label } />
            <BoxInfo title="Type de Compte" value={ compteType.label } />
            <BoxInfo title="Compte Vérifié" value={isAuthorized.label} />
        </Grid>
    </Container>
    );
}