import { Container, Grid } from '@cloudscape-design/components';
import BoxInfo from '../customs/boxInfo';


export default function BalanceInfo({ account }) {

    return(
        <Container>
            <Grid
                gridDefinition={[
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 3}},
                ]}
            >
                <BoxInfo 
                    title="Valeur Totale" 
                    value={account.totalBalance} 
                    category='currency'
                    decimals={0}
                />
                <BoxInfo 
                    title="Taux Annuel Moyen" 
                    value={account.avgAnnualYield} 
                    category='percent'                    
                />
                <BoxInfo 
                    title="Balance (Placement)" 
                    value={account.balanceInvestment} 
                    category='currency'
                    decimals={0}
                />
                <BoxInfo 
                    title="Taux Annuel (Placement)" 
                    value={account.investmentAnnualYield} 
                    category='percent'                    
                />
                <BoxInfo 
                    title="Gain" 
                    value={account.totalEarned} 
                    category='currency'
                    decimals={0}
                />
                <BoxInfo 
                    title="Balance (Rente)" 
                    value={account.balancePension} 
                    category='currency'
                    decimals={0}
                />
                <BoxInfo 
                    title="Taux Annuel (Rente)" 
                    value={account.pensionAnnualYield} 
                    category='percent'                    
                />
                <BoxInfo
                    title="Rente Mensuelle (€)"
                    value={account.monthlyPayment}
                    category='currency'
                    decimals={0}
                />
                <BoxInfo 
                    title="Net Payé (€)" 
                    value={account.totalPaid} 
                    category='currency'
                    decimals={0}
                />

                {account.taxPaid > 0 && (
                    <BoxInfo 
                        title="Impôts Payés" 
                        value={account.taxPaid} 
                        category='currency'
                        decimals={0}
                    />
                )}
                
            </Grid> 
        </Container>
    )
}
