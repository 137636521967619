import Header from '@cloudscape-design/components/header';
import MainLayout from '../layout/MainLayout';
import { Container, ContentLayout } from '@cloudscape-design/components';
import { useLoaderData } from 'react-router-dom';
import FintechForm from '../components/fintech/FintechForm';


export default function AddFintech() {

    const { fintechs, info21yield, breadcrumbs } = useLoaderData();

    return (
        <MainLayout breadcrumbs={breadcrumbs} activeHref={"fintechs/ajouter"}>
        <ContentLayout>
            <Container
                header={
                    <Header
                    variant="h1"
                    description="21yield"
                    
                    >
                    Ajouter une nouvelle Fintech
                    </Header>
                }
            >
                <FintechForm fintechs={fintechs} info21yield={info21yield} />
                
            </Container>
        </ContentLayout>
        </MainLayout>
    );
}