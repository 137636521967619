import { Button, Box, Container, SpaceBetween, ContentLayout } from "@cloudscape-design/components";
import { useEffect } from 'react';

import MainLayout from '../layout/MainLayout';
import { checkAuth } from '../services/calls/checkAuth';


export default function Home() {

    let breadcrumbs = [{
        text: 'Welcome Back !',
        href: '#',
    }];
    
    useEffect(() => {
        checkAuth();
        // TODO : add alert if not authenticated
        
    }, []);

    return (
        <MainLayout breadcrumbs={breadcrumbs} >
        <ContentLayout>
            
        <SpaceBetween direction="vertical" size="s">
        <Container
                media={{
                    content: (
                    <img
                        src="/logo192.png"
                        alt="placeholder"
                    />
                    ),
                    position: "side",
                }}
                >
                <SpaceBetween direction="vertical" size="s">
                    <SpaceBetween direction="vertical" size="xxs">
                        <Box variant="h2">Gestion du Fonds</Box>
                        <Box variant="small">21yield Admin</Box>
                    </SpaceBetween>
                    <Box variant="p">Pilotage de la performance du fonds et des fintechs</Box>
                    <Button variant='primary' href='/performance'>Accéder</Button> 
                </SpaceBetween>
            </Container>
            <Container
                media={{
                    content: (
                    <img
                        src="/logo192.png"
                        alt="placeholder"
                    />
                    ),
                    position: "side"
                }}
                >
                <SpaceBetween direction="vertical" size="s">
                    <SpaceBetween direction="vertical" size="xxs">
                        <Box variant="h2">Gestion des Clients</Box>
                        <Box variant="small">21yield Admin</Box>
                    </SpaceBetween>
                    <Box variant="p">Gestion des comptes client et des opérations</Box>
                    <Button variant='primary' href='/comptes'>Accéder</Button> 
                </SpaceBetween>
            </Container>
        </SpaceBetween>
            
        </ContentLayout>
        </MainLayout>
    );
}