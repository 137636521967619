import { Header, Container, ContentLayout, SpaceBetween } from '@cloudscape-design/components';
import { useLoaderData } from 'react-router-dom';

import MainLayout from '../layout/MainLayout';
import AccountForm from '../components/account/AccountForm';
import CustomSelect from '../components/customs/CustomSelect';
import { LIST_TYPE_COMPTE } from '../constants/global_lists';
import { useState } from 'react';


export default function AddAccount() {

    const { breadcrumbs } = useLoaderData();

    let [compteType, setCompteType] = useState('');

    return (
        <MainLayout breadcrumbs={breadcrumbs} activeHref={"comptes/ajouter"}>
        <ContentLayout>
            <Container
                header={
                    <Header
                    variant="h1"
                    description="21yield"
                    
                    >
                    Ajouter un nouveau Compte Client
                    </Header>
                }
            >   
                <SpaceBetween direction="vertical" size="l">
                    <CustomSelect
                        selectOptions={LIST_TYPE_COMPTE}
                        value={compteType}
                        setValue={setCompteType}
                        name="Type de Compte"
                    />
                    {compteType !== '' && <AccountForm compteType={compteType} />}
                </SpaceBetween>
            </Container>
        </ContentLayout>
        </MainLayout>
    );
}