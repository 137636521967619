import { 
    Container,
    ColumnLayout,
} from "@cloudscape-design/components";
import BoxInfo from "../customs/boxInfo";



export default function AddressInfoCard({ address, addressComplement, city, zipCode, country }) {

    return(
    <Container>
        <ColumnLayout
            columns={3}
            variant="text-grid"
        >
            <BoxInfo title="Adresse" value={address} />
            <BoxInfo title="Complément d'Adresse" value={addressComplement} />
            <BoxInfo title="Ville" value={city} />
            <BoxInfo title="Code Postal" value={zipCode} />
            <BoxInfo title="Pays" value={country} />
            
        </ColumnLayout>
    </Container>
    )
}