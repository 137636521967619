import protectedLoader from "./protectedLoader";


export async function addAccountLoader({ request }) {

    const redirection = protectedLoader({request});
    if (redirection) {
      return redirection;
    }
  
    const breadcrumbs = [
        {
            text: 'Gestion des Comptes',
            href: '/',
        },
        {
            text: 'Comptes',
            href: '/comptes',
        },
        {
            text: 'Ajouter un Compte Client',
        },
  ];

    return { breadcrumbs };
  }
  