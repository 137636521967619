import { OPTION_ACTIVE } from "../../constants/global_lists";
import { updateInfo21yield } from "../../services/calls/21yield";
import consoleOnlyLocally from "../../utils/consoleOnlyLocally";


export default async function updatePerformance(fintech, fintechs, info21yield) {

    consoleOnlyLocally(fintech);
    consoleOnlyLocally(fintech.status.label);

    // Initialize the values with the updated fintech
    let newTotalLoaned = 0;
    let sumRawYield = 0;
    let sumRawDefault = 0;
    let sumLoanYield = 0;
    let sumRatioCollateral = 0;

    if(fintech.status.label === OPTION_ACTIVE.label) {
        newTotalLoaned = fintech.loanAmount;
        sumRawYield = fintech.loanAmount * fintech.rawYield;
        sumRawDefault = fintech.loanAmount * fintech.rawDefault;
        sumLoanYield = fintech.loanAmount * fintech.loanYield;
        sumRatioCollateral = fintech.loanAmount * fintech.ratioCollateral;
    }
    // Calculate the average yield for all fintechs
    // for all fintechs except the one that has been updated add rawYield multiplied by the loan amount
    fintechs.forEach((f) => {

        // Only consider active fintechs
        if(f.status.label === OPTION_ACTIVE.label && f.fintechID !== fintech.fintechID) {
            consoleOnlyLocally("adding fintech")
            consoleOnlyLocally(f);
            sumRawYield += f.rawYield * f.loanAmount;
            sumRawDefault += f.rawDefault * f.loanAmount;
            sumLoanYield += f.loanYield * f.loanAmount;
            sumRatioCollateral += f.ratioCollateral * f.loanAmount;
            newTotalLoaned += f.loanAmount;
        }
    });
    let avgRawYield = sumRawYield / newTotalLoaned;
    let avgRawDefault = sumRawDefault / newTotalLoaned;
    let avgLoanYield = sumLoanYield / newTotalLoaned;
    let avgRatioCollateral = sumRatioCollateral / newTotalLoaned;

    var data21yield = {
        infoID: info21yield.infoID,
        totalLoaned: newTotalLoaned,
        rawYield: avgLoanYield,
        ratioCollateral: avgRatioCollateral,
        borrowerYield: avgRawYield,
        borrowerDefault: avgRawDefault
    }
    await updateInfo21yield(data21yield)

}