import { Button, Container, ContentLayout, SpaceBetween, Header, Table } from '@cloudscape-design/components';
import { useLoaderData } from 'react-router-dom';

import MainLayout from '../layout/MainLayout';
import { toFormatPercent } from '../utils/toFormat';


export default function AvailableAssets() {

    const { availableAssets, breadcrumbs } = useLoaderData();

    const column_definitions = [
        {
            id: 'label',
            header: 'Nom du produit',
            cell: item => item.label,
            isRowHeader: true,
        },
        {
            id: 'annualYield',
            header: 'Rendement Annuel (en %)',
            cell: item => toFormatPercent(item.annualYield),
        },
        {
            id: 'waitingPeriod',
            header: 'Délai de carence (en jours)',
            cell: item => item.waitingPeriod,
        },
        {
            id: 'clientType',
            header: 'Type de client',
            cell: item => item.clientType.label,
        },
        {
            id: 'investmentType',
            header: 'Catégorie Investissement',
            cell: item => item.investmentType.label,
        },
        {
          id: "edit",
          header: "Modifier",
          cell: item => <div className="icon-container"><Button href={`/produit-financier/${item.availableAssetID}`} variant="secondary" iconName="edit">Modifier</Button></div>,
        }
    ]

    return (
        <MainLayout breadcrumbs={breadcrumbs} activeHref={"actifs"}>
        <ContentLayout>
            <Container
                header={
                    <Header
                        variant="h1"
                        description="21yield"
                        actions={
                            <SpaceBetween
                            direction="horizontal"
                            size="xs"
                            >
                            <Button href='/produits-financiers/ajouter' variant='primary'>Ajouter</Button>
                            </SpaceBetween>
                        }
                    >
                    Produits Financiers
                    </Header>
                }
            >
                <Table
                    resizableColumns={true}
                    variant="stacked"
                    columnDefinitions={column_definitions}
                    items={availableAssets}
                />
            </Container>
        </ContentLayout>
        </MainLayout>
    );
}