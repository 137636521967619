

export async function addAssetLoader({ request }) {

    const breadcrumbs = [
        {
            text: 'Gestion du Fonds',
            href: '/',
        },
        {
            text: 'Produits Financiers',
            href: '/produits-financiers',
        },
        {
            text: 'Ajouter un Produit',
        },
  ];

    return { breadcrumbs };
  }
  