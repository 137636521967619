import Button from '@cloudscape-design/components/button';
import Header from '@cloudscape-design/components/header';
import SpaceBetween from '@cloudscape-design/components/space-between';
import MainLayout from '../layout/MainLayout';
import { Container, ContentLayout, Grid, Table } from '@cloudscape-design/components';

import { useLoaderData } from 'react-router-dom';
import { useState } from 'react';
import EditPerformance from '../components/performance/editPerformance';
import BoxInfo from '../components/customs/boxInfo';
import BankInfoCard from '../components/account/bankInfoCard';
import { toFormatCurrency, toFormatPercent } from '../utils/toFormat';
import ValueWithLabel from '../components/customs/ValueWithLabel';
import { OPTION_ACTIVE } from '../constants/global_lists';


export default function Performance() {
  
  const { fintechs, info21yield, breadcrumbs } = useLoaderData();

  let numberActives = 0;
  fintechs.forEach((f) => {
    if(f.status.label === OPTION_ACTIVE.label) {
      numberActives++;
    }
  });

  let [openEdit, setOpenEdit] = useState(false);

  function ContentCard() {
    return(
      <Grid gridDefinition={[
        { colspan: { default: 12, xxs: 3 } },
        { colspan: { default: 12, xxs: 3 } },
        { colspan: { default: 12, xxs: 3 } },
        { colspan: { default: 12, xxs: 3 } },
        { colspan: { default: 12, xxs: 3 } },
      ]}>
        <ValueWithLabel label="AUM">
          <div className='value-main'>{toFormatCurrency(info21yield.totalLoaned)}</div>
        </ValueWithLabel>
        
        <ValueWithLabel label="Nombre de fintechs">
          <div className='value-main'>{numberActives}</div>
        </ValueWithLabel>

        <ValueWithLabel label="Rendement Brut (en %)">
          <div className='value-main'>{toFormatPercent(info21yield.rawYield)}</div>
        </ValueWithLabel>

        <ValueWithLabel label="Taux de défaut (en %)">
          <div className='value-main'>{toFormatPercent(info21yield.rawDefault)}</div>
        </ValueWithLabel>
        
        <ValueWithLabel label="Performance fintechs (en %)">
          <div className='value-main'>{toFormatPercent(info21yield.borrowerYield)}</div>
        </ValueWithLabel>
        
        <ValueWithLabel label="PAR90 fintechs (en %)">
          <div className='value-main'>{toFormatPercent(info21yield.borrowerDefault)}</div>
        </ValueWithLabel>

      </Grid>
  )}

  return (
    <MainLayout breadcrumbs={breadcrumbs} activeHref={"performance"}>
      <ContentLayout>
      <SpaceBetween direction="vertical" size="l">
        <Container
          header={
            <Header
              variant="h2"
              // actions={
              //   <SpaceBetween
              //     direction="horizontal"
              //     size="xs"
              //   >
              //     <Button variant={openEdit ? "secondary" : 'primary'} iconName={openEdit ? "close": "edit"} onClick={() => setOpenEdit(!openEdit)}>{openEdit ? "Close" : (info21yield ? "Edit" : "Create")}</Button>
              //   </SpaceBetween>
              // }
            >
              Performance de 21yield
            </Header>
          }
        >
          {openEdit && (<EditPerformance info21yield={info21yield} setOpenEdit={setOpenEdit} />)}          
          {!openEdit && info21yield &&  <ContentCard />}
        </Container>  

        <Container
          header={
            <Header
              variant="h2"
              actions={
                <SpaceBetween
                  direction="horizontal"
                  size="xs"
                >
                  <Button href='/fintechs/ajouter' iconName='add-plus' variant='primary'>Ajouter</Button>
                </SpaceBetween>
              }
            >
            Nos Emprunteurs (Fintechs)
            </Header>
          }
        >

          <Table
            resizableColumns={true}
            variant="stacked"
            columnDefinitions={[
              {
                id: 'name',
                header: 'Nom de la Fintech',
                cell: item => item.name,
                isRowHeader: true,
              },{
                id: 'loanAmount',
                header: 'Montant prêté (en EUR)',
                cell: item => toFormatCurrency(item.loanAmount),
              },
              {
                id: 'loanYield',
                header: 'Taux du prêt (en %)',
                cell: item => toFormatPercent(item.loanYield),
              },
              {
                id: 'rawYield',
                header: 'Rendement brut (en %)',
                cell: item => toFormatPercent(item.rawYield),
              },
              {
                id: 'rawDefault',
                header: 'Taux de défaut (en %)',
                cell: item => toFormatPercent(item.rawDefault),
              },
              {
                id: 'drawdownLimit',
                header: 'Facilité Maximale (en EUR)',
                cell: item => toFormatCurrency(item.drawdownLimit),
              },
              {
                id: 'status',
                header: 'Statut de la relation',
                cell: item => item.status.label,
              },
              {
                id: "edit",
                header: "Modifier",
                cell: item => <div className="icon-container"><Button href={`/fintech/${item.fintechID}`} variant="secondary" iconName="edit">Modifier</Button></div>,
              }
            ]}
            items={fintechs}
          />
        </Container>

        <BankInfoCard
          title={"Compte Bancaire - fonds des clients"}
          iban={info21yield.bankIban}
          bic={info21yield.bankBic}
          name={info21yield.bankName}
          bankVerified={info21yield.bankVerified}
        />

      </SpaceBetween>
      </ContentLayout>
    </MainLayout>
  );
}