import { getAccount } from "../services/calls/account";
import { getOperation } from "../services/calls/operation";
import protectedLoader from "./protectedLoader";


export async function operationLoader({ request, params }) {

    const redirection = protectedLoader({request});
    if (redirection) {
        return redirection;
    }

    var data = await getOperation({operationID: params.operationID});
    const operation = data.operation;

    var data2 = await getAccount({accountID: operation.accountID});
    const account = data2.account;

    const breadcrumbs = [
        {
            text: 'Gestion des Comptes',
            href: '/',
        },
        {
            text: 'Operations',
            href: "/operations"
        },
        {
            text: "Operation: " + params.operationID
        }
    ];

        return { operation, account, breadcrumbs };
}
