import { Box, Container, Grid } from "@cloudscape-design/components";
import BoxInfo from "../customs/boxInfo";


export default function BankInfoCard({ title, iban, bic, name, bankVerified }) {
    
    return (
        <Container>
            <Box variant="h4">{title}</Box>
            <Grid 
                gridDefinition={[
                    { colspan: { default: 12, xxs: 4 } },
                    { colspan: { default: 12, xxs: 2 } },
                    { colspan: { default: 12, xxs: 3 } },
                    { colspan: { default: 12, xxs: 3 } },
                ]}
            >
                <BoxInfo
                    title={"IBAN"}
                    value={iban}
                    category={"iban"}
                />
                <BoxInfo
                    title={"BIC"}
                    value={bic}
                />
                <BoxInfo
                    title={"Nom de la Banque"}
                    value={name}
                />
                <BoxInfo
                    title={"Statut Vérification"}
                    value={bankVerified.label}
                />

            </Grid>
        </Container>
    );
}