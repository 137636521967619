import consoleOnlyLocally from "./consoleOnlyLocally";
import { diffDays, getUTCDateFromISOStringDate } from "./dateUtils";


function customExp(R, k, d) {
    const base = 1 + R;
    const exponent = k / d;

    return base ** exponent
    // return power(base, exponent);
}

export function calculateCompoundedInterest(fromDate, toDate, annualYield, capital) {
    var nbDays = diffDays(fromDate, toDate);
    var interest = customExp(annualYield, nbDays, 365) - 1
    return capital * interest;
}

export function calculateCompoundedValue(fromDate, toDate, annualYield, capital) {
    var nbDays = diffDays(fromDate, toDate);
    var value = customExp(annualYield, nbDays, 365)
    return capital * value;
}

export function calculateMonthlyPayment(capital, annualYield) {
    var payout = capital * annualYield / 12;
    return payout;
}


export function calculateFirstPaymentInfo(theInvestmentDate, amount, annualYield){

    var date = getUTCDateFromISOStringDate(theInvestmentDate);
    console.log("from date " + date)

    // -------  Calculation for this month   ------- //
    console.log("This month")
    // get the number of days in the month
    var endThisMonth = new Date(Date.UTC(date.getFullYear(), date.getMonth()+1, 0))
    console.log("Current month " + endThisMonth)
    var daysInCurrentMonth = endThisMonth.getDate();
    console.log("total day in month: " +daysInCurrentMonth)

    // get 1th day of the following month of the date of investment, so diffence include last day of the month
    var endThisMonthDate = new Date(Date.UTC(date.getFullYear(), date.getMonth() + 1, 1));
    var currentPeriodeDuration = endThisMonthDate - getUTCDateFromISOStringDate(theInvestmentDate);
    //  set the diff in days
    currentPeriodeDuration = currentPeriodeDuration / (1000 * 60 * 60 * 24);
    console.log("duration: " + currentPeriodeDuration + " jours");

    // calculate the ratio of days for the month
    var ratioThisMonth = currentPeriodeDuration / daysInCurrentMonth;
    console.log(ratioThisMonth)

    // -------  Calculation for next month   ------- //
    console.log("Next month")
    var endNextMonth = new Date(Date.UTC(date.getFullYear(), date.getMonth()+2, 0))
    var daysInNextMonth = endNextMonth.getDate()
    console.log("total day in next month: " +daysInNextMonth)

    // get 1th day of the following month of the date of investment
    var endNextMonthDate = new Date(Date.UTC(date.getFullYear(), date.getMonth() + 1, 4));
    var nextPeriodeDuration = endNextMonthDate - endThisMonth;
    //  set the diff in days
    nextPeriodeDuration = nextPeriodeDuration / (1000 * 60 * 60 * 24);
    console.log("duration: " + nextPeriodeDuration + " jours");

    // calculate the ratio of days for the month
    var ratioNextMonth = nextPeriodeDuration / daysInNextMonth;
    console.log(ratioNextMonth)

    // -------  Calculation for next month   ------- //
    var totalRatio = ratioThisMonth + ratioNextMonth;
    var totalDays = daysInCurrentMonth + daysInNextMonth;
    var duration = currentPeriodeDuration + nextPeriodeDuration;
    var endPeriod = endNextMonthDate;

    // calculate the first payment value in euros
    var firstPayment = calculateMonthlyPayment(parseFloat(amount), annualYield) * totalRatio;
    console.log(firstPayment + " €")

    return { endPeriod, duration, totalDays, totalRatio, firstPayment };
}

export function calculateLastPaymentInfo(toDate, amount, annualYield) {
    // Calculate the payout between the 5th day of the month and toDate

    // get the number of days in the month
    var endThisMonth = new Date(Date.UTC(toDate.getFullYear(), toDate.getMonth()+1, 0));
    var totalDays = endThisMonth.getDate();

    // get the number of days in the month
    var duration = toDate - new Date(Date.UTC(toDate.getFullYear(), toDate.getMonth(), 5));
    duration = duration / (1000 * 60 * 60 * 24);

    // calculate the ratio of days for the month
    var totalRatio = duration / totalDays;

    // calculate the last payment value in euros
    var lastPayment = calculateMonthlyPayment(parseFloat(amount), annualYield) * totalRatio;

    return { duration, totalDays, totalRatio, lastPayment };
}