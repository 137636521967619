import { postCalltoAPI, getCalltoAPI } from "../axiosCall";


// Method GET
export async function getAsset() {
    
  var response = await getCalltoAPI( "/get_asset");
  return response;
}


// Method POST
export async function createAsset(sendData) {
    
    var response = await postCalltoAPI( "/create_asset", sendData);
    return response;
}


export async function updateAsset(sendData) {
    
    var response = await postCalltoAPI( "/update_asset", sendData);
    return response;
}

export async function getAllAssetsInAccount(sendData) {

    var response = await postCalltoAPI( "/get_all_assets_in_account", sendData);
    return response;
  }

export async function getAssetForAccountAndAvailableAsset(sendData) {

    var response = await postCalltoAPI( "/get_asset_for_account_and_available_asset", sendData);
    return response;
}
  