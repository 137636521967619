import Header from '@cloudscape-design/components/header';
import MainLayout from '../layout/MainLayout';
import { Box, Container, ContentLayout } from '@cloudscape-design/components';
import { useLoaderData } from 'react-router-dom';
import AvailableAssetForm from '../components/AvailableAssetForm';


export default function AddAvailableAsset() {

    const { breadcrumbs } = useLoaderData();

    return (
        <MainLayout breadcrumbs={breadcrumbs} activeHref={"actifs/ajouter"}>
        <ContentLayout>
            <Container
                header={
                    <Header
                    variant="h1"
                    description="21yield"
                    
                    >
                    Ajouter un nouveau Produit Financier
                    </Header>
                }
            >
                <AvailableAssetForm />

            </Container>
        </ContentLayout>
        </MainLayout>
    );
}