
import { ContentLayout, } from '@cloudscape-design/components';
import { useLoaderData } from 'react-router-dom';

import MainLayout from '../layout/MainLayout';
import FintechForm from '../components/fintech/FintechForm';


export default function Fintech() {

    const { fintech, fintechs, info21yield, breadcrumbs } = useLoaderData();

    return (
        <MainLayout breadcrumbs={breadcrumbs} activeHref={"fintechs"}>
        <ContentLayout>

            <FintechForm fintech={fintech} fintechs={fintechs} info21yield={info21yield} />
    
        </ContentLayout>
        </MainLayout>
    );
}