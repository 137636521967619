import { ContentLayout } from '@cloudscape-design/components';
import { useLoaderData } from 'react-router-dom';

import MainLayout from '../layout/MainLayout';
import TableOperations from '../components/tableOperations';


export default function Operations() {

    const { operations, breadcrumbs } = useLoaderData();

    return (
        <MainLayout breadcrumbs={breadcrumbs} activeHref={"operations"}>
        <ContentLayout>
            <TableOperations operations={operations} />
        </ContentLayout>
        </MainLayout>
    );
}