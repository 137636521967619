import { 
    Container,
    SpaceBetween,
    Grid
} from "@cloudscape-design/components";
import CustomInput from "../customs/CustomInput";
import CustomSelect from "../customs/CustomSelect";
import { LIST_CATEGORY_AUTHORIZED } from "../../constants/global_lists";


export default function BankForm({ bankIban, setBankIban, bankBic, setBankBic, bankName, setBankName, bankVerified, setBankVerified}) {

    return (
    // <Container>
        <SpaceBetween direction="vertical" size="l">
            <Grid 
                gridDefinition={[
                    { colspan: { default: 12, xxs: 5}},
                    { colspan: { default: 12, xxs: 2}},
                    { colspan: { default: 12, xxs: 3}},
                    { colspan: { default: 12, xxs: 2}},
                ]}
            >
                <CustomInput
                    label="IBAN"
                    placeholder="IBAN"
                    value={bankIban}
                    setValue={setBankIban}
                />
                <CustomInput
                    label="BIC"
                    placeholder="BIC"
                    value={bankBic}
                    setValue={setBankBic}
                />
                <CustomInput
                    label="Nom de la Banque"
                    placeholder="Nom de la Banque"
                    value={bankName}
                    setValue={setBankName}
                />

                <CustomSelect
                    selectOptions={LIST_CATEGORY_AUTHORIZED}
                    value={bankVerified}
                    setValue={setBankVerified}
                    name="Compte Vérifié"
                />

            </Grid>
        </SpaceBetween>
    // </Container>
    );
}