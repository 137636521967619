import {RouterProvider, createBrowserRouter, redirect  } from 'react-router-dom'
import { LoadScript } from '@react-google-maps/api'

import './App.css';
import './theme.css';
import Performance from './routes/Performance';
import ErrorPage from "./error-page";
import Home from './routes/Home';
import LoadingPage from './loading/Loading';
import { performanceLoader } from './loaders/performanceLoader';
import protectedLoader from './loaders/protectedLoader';
import Fintech from './routes/Fintech';
import { fintechLoader } from './loaders/fintechLoader';
import { availableAssetsLoader } from './loaders/availableAssetsLoader';
import AvailableAssets from './routes/AvailableAssets';
import { accountsLoader } from './loaders/accountsLoader';
import Accounts from './routes/Accounts';
import { operationsLoader } from './loaders/operationsLoader';
import Operations from './routes/Operations';
import { addAssetLoader } from './loaders/addAssetLoader';
import AddAvailableAsset from './routes/AddAvailableAsset';
import AddFintech from './routes/AddFintech';
import { addFintechLoader } from './loaders/addFintechLoader';
import { addAccountLoader } from './loaders/addAccountLoader';
import AddAccount from './routes/AddAccount';
import Account from './routes/Account';
import { accountLoader } from './loaders/accountLoader';
import { operationLoader } from './loaders/operationLoader';
import Operation from './routes/Operation';
import { addOperationLoader } from './loaders/addOperationLoader';
import AddOperation from './routes/AddOperation';
import { availableAssetLoader } from './loaders/availableAssetLoader';
import AvailableAsset from './routes/AvailableAsset';


import { I18n } from "@aws-amplify/core";
import { Amplify } from 'aws-amplify';
import { translations, Authenticator } from '@aws-amplify/ui-react';
import awsExports from "./aws-exports";
import '@aws-amplify/ui-react/styles.css';
import "./custom-auth.css"
import { SpaceBetween } from '@cloudscape-design/components';
import { signOut } from 'aws-amplify/auth';


Amplify.configure(awsExports);

I18n.putVocabularies(translations);
I18n.setLanguage('fr');

I18n.putVocabularies({
  fr: {
    'Sign In': 'Se connecter',
    'Reset Password': 'Choisir un nouveau Mot de Passe',
    'Enter your Email': "Email",
    'Enter your Password': 'Mot de passe',
    Email: "Entrez votre email",
    Password: 'Entrez votre mot de passe',
    'Forgot your password?': 'Choisir un nouveau Mot de Passe',
    'Back to Sign In': 'Revenir à la page de connexion',
  },
});


export default function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      loader: protectedLoader,
      Component: Home,
      errorElement: <ErrorPage />,
    },
    {
      path: "/performance",
      loader: performanceLoader,
      Component: Performance,
      errorElement: <ErrorPage />,
      
    },
    {
      path: "/fintech/:fintechID",
      loader: fintechLoader,
      Component: Fintech,
      errorElement: <ErrorPage />,
      
    },
    {
      path: "/fintechs/ajouter",
      loader: addFintechLoader,
      Component: AddFintech,
      errorElement: <ErrorPage />,
      
    },
    {
      path: "/produits-financiers",
      loader: availableAssetsLoader,
      Component: AvailableAssets,
      errorElement: <ErrorPage />,
    },
    {
      path: "/produit-financier/:availableAssetID",
      loader: availableAssetLoader,
      Component: AvailableAsset,
      errorElement: <ErrorPage />,
    },
    {
      path: "/produits-financiers/ajouter",
      loader: addAssetLoader,
      Component: AddAvailableAsset,
      errorElement: <ErrorPage />,
    },
    {
      path: "/comptes",
      loader: accountsLoader,
      Component: Accounts,
      errorElement: <ErrorPage />,
      
    },
    {
      path: "/comptes/compte/:accountID",
      loader: accountLoader,
      Component: Account,
      errorElement: <ErrorPage />,
      
    },
    {
      path: "/comptes/ajouter",
      loader: addAccountLoader,
      Component: AddAccount,
      errorElement: <ErrorPage />,
      
    },
    {
      path: "/operations",
      loader: operationsLoader,
      Component: Operations,
      errorElement: <ErrorPage />,
      
    },
    {
      path: "/operations/ajouter",
      loader: addOperationLoader,
      Component: AddOperation,
      errorElement: <ErrorPage />,
      children: [
        {
          path: ":accountID",
          loader: addOperationLoader,
          Component: AddOperation,
          errorElement: <ErrorPage />,
        },
      ],
      
    },
    {
      path: "/operations/operation/:operationID",
      loader: operationLoader,
      Component: Operation,
      errorElement: <ErrorPage />,
      
    },

    {
      path: "/logout",
      async action() {
        // We signout in a "resource route" that we can hit from a fetcher.Form
        signOut();
        return redirect("/");
      },
    }
  ]);
      
  const components = {
    Header() {
        return (
          <SpaceBetween>
            <div className='login-container'>
              <div className='login-img' />
              <div className='login-text'>
                21yield - Pilotage Admin des Opérations - v3
              </div>
            </div>
          </SpaceBetween>
        );
    },

    Footer() {
        return (
          <div className='footer-container'>
            <div className="footer-text">21yield&copy; Copyright, tous droits réservés</div>
          </div>
        );
    },
  }

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      libraries={["places"]}
    >
      <Authenticator hideSignUp={true} components={components}>
        <RouterProvider router={router} fallbackElement={<LoadingPage />} />
      </Authenticator>
    </LoadScript>
  );
}
