
import { useLayoutEffect, useState } from 'react';
import { useCollection } from '@cloudscape-design/collection-hooks';

import { TableEmptyState, TableNoMatchState } from '../commons/common-components';
import { useColumnWidths } from '../commons/use-column-widths';
import { useLocalStorage } from '../commons/use-local-storage';

import TableSelectFilter from './customs/tableFiltered';
import CustomSelect from './customs/CustomSelect';
import { prepareSelectOptionsFromDictionary } from '../utils/prepareSelectOptions';
import getAllKeys from '../utils/getAllKeys';
import consoleOnlyLocally from '../utils/consoleOnlyLocally';

import { Link } from '@cloudscape-design/components';
import { createTableSortLabelFn } from './customs/table';
import { toFormatCurrency, toFormatPercent } from '../utils/toFormat';

const rawColumns = [
  {
    id: 'name',
    header: 'Nom',
    cell: item => <Link href={`/comptes/compte/${item.accountID}`}>{item.name}</Link>,
    sortingField: 'name',
    // sortingComparator: (item1, item2) => item1.id.substring(13, item2.length) - item2.id.substring(13, item1.length),
  },
  {
    id: 'clientType',
    header: 'Type Client',
    cell:  item  => item.clientType.label,
    sortingField: 'clientType',
  },
  {
    id: 'compteType',
    header: 'Type Compte',
    cell:  item  => item.compteType.label,
    sortingField: 'compteType',
  },
  {
    id: "avgAnnualYield",
    header: "Taux Annuel Moyen",
    cell: item => toFormatPercent(item.avgAnnualYield)
  },
  {
    id: "totalBalance",
    header: "Balance",
    cell: item => toFormatCurrency(item.totalBalance)
  },
  {
    id: "balanceInvestment",
    header: "Balance (Placement)",
    cell: item => toFormatCurrency(item.balanceInvestment)
  },
  {
    id: "investmentAnnualYield",
    header: "Taux Annuel Moyen",
    cell: item => toFormatPercent(item.investmentAnnualYield)
  },
  {
    id: "totalEarned",
    header: "Gain",
    cell: item => toFormatCurrency(item.totalEarned)
  },
  {
    id: "balancePension",
    header: "Balance (Rente)",
    cell: item => toFormatCurrency(item.balancePension)
  },
  {
    id: "pensionAnnualYield",
    header: "Taux Annuel Moyen",
    cell: item => toFormatPercent(item.pensionAnnualYield)
  },
  {
    id: "monthlyPayment",
    header: "Monthly Payment",
    cell: item => toFormatCurrency(item.monthlyPayment)
  },
  {
    id: "totalPaid",
    header: "Payé",
    cell: item => toFormatCurrency(item.totalPaid)
  },
];

const COLUMN_DEFINITIONS = rawColumns.map(column => ({ ...column, ariaLabel: createTableSortLabelFn(column) }));

const CONTENT_DISPLAY_OPTIONS = [
  { id: 'name', label: 'Nom', visible: true },
  { id: 'clientType', label: 'Type Client', visible: true },
  { id: 'compteType', label: 'Type Compte', visible: true },
  { id: 'avgAnnualYield', label: 'Taux Annuel Moyen', visible: true },
  { id: 'totalBalance', label: 'Balance', visible: true },
  { id: 'balanceInvestment', label: 'Balance (Placement)', visible: true },
  { id: 'investmentAnnualYield', label: 'Taux Annuel (Placement)', visible: false },
  { id: 'totalEarned', label: 'Gain', visible: true },
  { id: 'balancePension', label: 'Balance (Rente)', visible: true },
  { id: 'pensionAnnualYield', label: 'Taux Annuel (Rente)', visible: false },
  { id: 'monthlyPayment', label: 'Monthly Payment', visible: true },
  { id: 'totalPaid', label: 'Payé', visible: true },
  { id: 'status', label: 'Status', visible: true }
];


export default function TableAccounts({ accounts }) {

    const allKeys = getAllKeys(accounts);
    const searchable_columns = allKeys;

    const [columnDefinitions, saveWidths] = useColumnWidths('React-TableSelectFilter-Widths', COLUMN_DEFINITIONS);
    const [preferences, setPreferences] = useLocalStorage('React-TableSelectFilter-Preferences', {
        pageSize: 30,
        contentDisplay: CONTENT_DISPLAY_OPTIONS,
        wrapLines: false,
        stripedRows: false,
        contentDensity: 'comfortable',
        custom: 'table',
    });

    const defaultClientType = { value: '0', label: 'All' };
    const defaultCompteType = { value: '0', label: 'All' };

    const selectClientTypeOptions = prepareSelectOptionsFromDictionary(defaultClientType, accounts, 'clientType');
    const selectCompteTypeOptions = prepareSelectOptionsFromDictionary(defaultCompteType, accounts, 'compteType');

    const [clientType, setClientType] = useState(defaultClientType);
    const [compteType, setCompteType] = useState(defaultCompteType);

    function matchesclientType(item, selectedclientType) {
        return selectedclientType.value === defaultClientType.value || item.clientType.value === selectedclientType.value;
    }
    
    function matchescompteType(item, selectedcompteType) {
        return selectedcompteType.value === defaultCompteType.value || item.compteType.value === selectedcompteType.value;
    }

    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(accounts, {
        filtering: {
            empty: <TableEmptyState resourceName="Instance" />,
            noMatch: <TableNoMatchState onClearFilter={clearFilter} />,
            filteringFunction: (item, filteringText) => {
                if (!matchesclientType(item, clientType)) {
                    return false;
                }
                if (!matchescompteType(item, compteType)) {
                    return false;
                }
                const filteringTextLowerCase = filteringText.toLowerCase();

                return searchable_columns.map(key => item[key]).some(
                    value => typeof value === 'string' && value.toLowerCase().indexOf(filteringTextLowerCase) > -1
                );
            },
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: { defaultState: { sortingColumn: columnDefinitions[0] } },
        selection: {},
    });

    useLayoutEffect(() => {
        collectionProps.ref.current?.scrollToTop();
    }, [collectionProps.ref, filterProps.filteringText, compteType, clientType]);

    function clearFilter() {
        actions.setFiltering('');
        setClientType(defaultClientType);
        setCompteType(defaultCompteType);
    }

    return (
        <TableSelectFilter
            collectionProps={collectionProps} 
            paginationProps={paginationProps}
            preferences={preferences}
            columnDefinitions={columnDefinitions}
            items={items}
            saveWidths={saveWidths}
            setPreferences={setPreferences}
            displayOptions={CONTENT_DISPLAY_OPTIONS}
            titleHeader="Nos clients (Investisseurs)"
            description="21yield"
            routeDestination="/comptes/ajouter"
        >
            <CustomSelect selectOptions={selectClientTypeOptions} value={clientType} setValue={setClientType} name="Type Client" />
            <CustomSelect selectOptions={selectCompteTypeOptions} value={compteType} setValue={setCompteType} name="Type Compte" />
        </TableSelectFilter>
    );
}
