import { useState } from "react";
import { Button, ColumnLayout, SpaceBetween } from "@cloudscape-design/components";

import CustomInput from "../customs/CustomInput";
import CustomSelect from "../customs/CustomSelect";
import { LIST_CATEGORY_AUTHORIZED, LIST_TYPE_CLIENT, LIST_TYPE_COMPTE, OPTION_ENTREPRISE } from "../../constants/global_lists";
import AddressForm from "./addressForm";
import BankForm from "./bankForm";
import CompanyInfoForm from "./CompanyInfoForm";
import { Divider } from "@aws-amplify/ui-react";
import { updateAccount } from "../../services/calls/account";
import LoadingPage from "../../loading/Loading";


export default function AccountUpdateForm({ account }) {

    let [isLoading, setIsLoading] = useState(false);

    let [name, setName] = useState(account.name);
    let [clientType, setClientType] = useState(account.clientType);
    let [compteType, setCompteType] = useState(account.compteType);
    let [isAuthorized, setIsAuthorized] = useState(account.isAuthorized);

    let [address, setAddress] = useState(account.address);
    let [addressComplement, setAddressComplement] = useState(account.addressComplement);
    let [city, setCity] = useState(account.city);
    let [zipCode, setZipCode] = useState(account.zipCode);
    let [country, setCountry] = useState(account.country);

    let [bankIban, setBankIban] = useState(account.bankIban);
    let [bankBic, setBankBic] = useState(account.bankBic);
    let [bankName, setBankName] = useState(account.bankName);
    let [bankVerified, setBankVerified] = useState(account.bankVerified);

    // Add info for company
    let [registrationNumber, setRegistrationNumber] = useState(account.registrationNumber ? account.registrationNumber : null);
    let [registrationOffice, setRegistrationOffice] = useState(account.registrationOffice ? account.registrationOffice : null);
    let [vatNumber, setVatNumber] = useState(account.vatNumber ? account.vatNumber : null);
    let [businessSector, setBusinessSector] = useState(account.businessSector ? account.businessSector : null);
    let [codeNaf, setCodeNaf] = useState(account.codeNaf ? account.codeNaf : null);
    let [dateCreated, setDateCreated] = useState(account.dateCreated ? account.dateCreated : null);
    let [equity, setEquity] = useState(account.equity ? account.equity : null);

    async function handleValidation() {
        
        setIsLoading(true);

        var update_data = {
            accountID: account.accountID,
            name: name,
            clientType: clientType,
            compteType: compteType,
            isAuthorized: isAuthorized,
            address: address,
            addressComplement: addressComplement,
            city: city,
            zipCode: zipCode,
            country: country,
            bankIban: bankIban,
            bankBic: bankBic,
            bankName: bankName,
            bankVerified: bankVerified
        }
        if(compteType.value == OPTION_ENTREPRISE.value) {
            update_data = {
                ...update_data,
                registrationNumber: registrationNumber,
                registrationOffice: registrationOffice,
                vatNumber: vatNumber,
                businessSector: businessSector,
                codeNaf: codeNaf,
                dateCreated: dateCreated,
                equity: equity
            }
        }

        await updateAccount(update_data);

        window.location.reload();
    }

    if (isLoading) return <LoadingPage />

    return(
        <SpaceBetween size="l">

            <ColumnLayout columns={4}>
                <CustomInput
                    label="Nom du Compte"
                    value={name}
                    onChange={setName}
                />
                <CustomSelect
                    selectOptions={LIST_TYPE_CLIENT}
                    value={clientType}
                    setValue={setClientType}
                    name="Type de Client"
                />
                <CustomSelect
                    selectOptions={LIST_TYPE_COMPTE}
                    value={compteType}
                    setValue={setCompteType}
                    name="Type de Compte"
                />
                <CustomSelect
                    selectOptions={LIST_CATEGORY_AUTHORIZED}
                    name="Compte Vérifié"
                    value={isAuthorized}
                    setValue={setIsAuthorized}
                />
            </ColumnLayout>
            
            <Divider />

            <AddressForm
                address={address}
                setAddress={setAddress}
                addressComplement={addressComplement}
                setAddressComplement={setAddressComplement}
                city={city}
                setCity={setCity}
                zipCode={zipCode}
                setZipCode={setZipCode}
                country={country}
                setCountry={setCountry}
            />

            {account.compteType.value == OPTION_ENTREPRISE.value && (
                <>
                    
                    <Divider />

                    <CompanyInfoForm
                        registrationNumber={registrationNumber}
                        setRegistrationNumber={setRegistrationNumber}
                        registrationOffice={registrationOffice}
                        setRegistrationOffice={setRegistrationOffice}
                        vatNumber={vatNumber}
                        setVatNumber={setVatNumber}
                        businessSector={businessSector}
                        setBusinessSector={setBusinessSector}
                        codeNaf={codeNaf}
                        setCodeNaf={setCodeNaf}
                        dateCreated={dateCreated}
                        setDateCreated={setDateCreated}
                        equity={equity}
                        setEquity={setEquity}
                    />
                </>
            )}

            <Divider />

            <BankForm
                bankIban={bankIban}
                setBankIban={setBankIban}
                bankBic={bankBic}
                setBankBic={setBankBic}
                bankName={bankName}
                setBankName={setBankName}
                bankVerified={bankVerified}
                setBankVerified={setBankVerified}
            />
            
            <Divider />

            <Button variant="primary" onClick={handleValidation}>Enregistrer les modifications</Button>
        </SpaceBetween>
    )
}