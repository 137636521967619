import { Grid } from "@cloudscape-design/components";
import CustomInput from "../customs/CustomInput";
import CustomSelect from "../customs/CustomSelect";
import { LIST_CATEGORY_AUTHORIZED, LIST_TYPE_CLIENT } from "../../constants/global_lists";


export default function AccountInfoForm({ name, setName, clientType, setClientType, isAuthorized, setIsAuthorized }) {


    return (
        <Grid 
            gridDefinition={[
                { colspan: { default: 12, xxs: 6 } },
                { colspan: { default: 6, xxs: 3 } },
                { colspan: { default: 6, xxs: 3 } },
                { colspan: { default: 6, xxs: 3 } },
                { colspan: { default: 6, xxs: 3 } },
            ]}
        >
            <CustomInput
                label="Nom"
                placeholder="Nom du client"
                value={name}
                setValue={setName}
            />
            <CustomSelect
                selectOptions={LIST_TYPE_CLIENT}
                value={clientType}
                setValue={setClientType}
                name="Type de Client"
            />
            <CustomSelect
                selectOptions={LIST_CATEGORY_AUTHORIZED}
                value={isAuthorized}
                setValue={setIsAuthorized}
                name="Authorization"
            />
        </Grid>
    )
}