import { redirect } from "react-router-dom";
// import userpool from "../authentication/userpool";


export default function protectedLoader({ request }) {
    // If the user is not logged in and tries to access any protected route, 
    // will be redirected to `/login` with a `from` parameter that allows login to redirect back
    // to this page upon successful authentication
    
    // let user = userpool.getCurrentUser();
    // if (!user) {
    //   let params = new URLSearchParams();
    //   params.set("from", new URL(request.url).pathname);
    //   console.log("should redirect to login")
      
    //   return redirect("/login?" + params.toString());
    // }
  
    return null;
}