import { postCalltoAPI, getCalltoAPI } from "../axiosCall";


// Method GET
export async function getFintech() {
    
  var response = await getCalltoAPI( "/get_fintech");
  return response;
}

export async function getAllFintechs() {

  var response = await getCalltoAPI( "/get_all_fintechs");
  return response;
}


// Method POST
export async function createFintech(sendData) {
    
    var response = await postCalltoAPI( "/create_fintech", sendData);
    return response;
}


export async function updateFintech(sendData) {
    
    var response = await postCalltoAPI( "/update_fintech", sendData);
    return response;
}

export async function deleteFintech(fintechID) {

  var response = await postCalltoAPI( "/delete_fintech", {fintechID: fintechID});
  return response;
}