import { Header, Container, ContentLayout, SpaceBetween, Box, Grid, Button} from '@cloudscape-design/components';
import { useLoaderData } from 'react-router-dom';

import MainLayout from '../layout/MainLayout';
import CustomSelect from '../components/customs/CustomSelect';
import { LIST_CATEGORY_ADD_OPERATION, OPTION_CASHOUT, OPTION_INVESTMENT, OPTION_PAYMENT } from '../constants/global_lists';
import { useEffect, useState } from 'react';
import consoleOnlyLocally from '../utils/consoleOnlyLocally';
import BoxInfo from '../components/customs/boxInfo';
import AddInvestment from '../components/operation/addInvestment';
import AddCashout from '../components/operation/addCashout';
import AddPayment from '../components/operation/addPayment';
import BalanceInfo from '../components/account/balanceInfo';


export default function AddOperation() {

    const { accounts, account, breadcrumbs } = useLoaderData();
    consoleOnlyLocally({accounts, account, breadcrumbs});

    let multiAccounts = accounts.length > 0 ? true : false;
    let [selectedAccountID, setSelectedAccountID] = useState(multiAccounts ? null : account.accountID);
    let [selectedAccount, setSelectedAccount] = useState(multiAccounts ? null : account);
    let [selectedCategory, setSelectedCategory] = useState("");

    let listAccountID = accounts.map(account => {
        return {value: account.accountID, label: account.name};
    });

    useEffect(() => {
        if(selectedAccountID !== null && accounts.length > 0) { 
            consoleOnlyLocally("Load Account from selected ID");
            loadAccount();
        }
    }, [selectedAccountID]);

    async function loadAccount() {
        const theAccount = accounts.find(account => account.accountID === selectedAccountID.value);
        consoleOnlyLocally(theAccount);
        setSelectedAccount(theAccount);
    }

    function createTitle() {
        if(selectedCategory.label === OPTION_INVESTMENT.label) return "Ajouter un Investissement au compte de " + selectedAccount.name;
        if(selectedCategory.label === OPTION_CASHOUT.label) return "Planifier un Retrait au compte de " + selectedAccount.name;
        if(selectedCategory.label === OPTION_PAYMENT.label) return "Ajouter un Paiement de rente au compte de " + selectedAccount.name;

        return "Ajouter une Opération à un Compte Client";
    }

    return (
        <MainLayout breadcrumbs={breadcrumbs} activeHref={"comptes/ajouter"}>
        <ContentLayout>
            <SpaceBetween direction="vertical" size="m">
            {(selectedAccountID === null && !multiAccounts) && <Container
            >
                <SpaceBetween direction="vertical" size="l">
                    <Box variant='h2'>Pas de Compte Client</Box>
                    <Button variant='primary' href='/comptes/ajouter'>Créer un Compte Client</Button>
                </SpaceBetween>
            </Container>
            }
            {(selectedAccountID !== null || multiAccounts) && <Container
                header={
                    <Header
                        variant="h2"
                    >
                    Ajouter une Opération à un Compte Client
                    </Header>
                }
            >   
                <Grid
                    gridDefinition={[
                        {colspan: {default: 12, xs: 3}},
                        {colspan: {default: 12, xs: 2}},
                        {colspan: {default: 12, xs: 2}},
                        {colspan: {default: 12, xs: 3}}
                    ]}
                >
                    {multiAccounts ? (
                    <CustomSelect
                        selectOptions={listAccountID}
                        value={selectedAccountID}
                        setValue={setSelectedAccountID}
                        name="Choisir un Compte Client"
                    />) : (
                        <BoxInfo
                            title="Compte Client"
                            value={account.name}
                        />
                    )}
                    {!multiAccounts && <BoxInfo
                        title="Type de Compte"
                        value={account.compteType.label}
                    />}
                    {!multiAccounts && <BoxInfo
                        title="Type de Client"
                        value={account.clientType.label}
                    />}

                    <CustomSelect
                        selectOptions={LIST_CATEGORY_ADD_OPERATION}
                        value={selectedCategory}
                        setValue={setSelectedCategory}
                        name="Choisir le type d'opération"
                    />
                    
                </Grid>
                <SpaceBetween direction="vertical" size="l">

                    
                </SpaceBetween>
            </Container>}

            {selectedAccount && (<BalanceInfo account={selectedAccount} />)}

            {selectedAccount && selectedCategory !== "" && <Container>
                <SpaceBetween direction="vertical" size="l">
                    <Box variant="h2">{createTitle()}</Box>


                    {selectedCategory.label === OPTION_INVESTMENT.label && <AddInvestment account={selectedAccount} />}
                    {selectedCategory.label === OPTION_CASHOUT.label && <AddCashout account={selectedAccount} />}
                    {selectedCategory.label === OPTION_PAYMENT.label && <AddPayment account={selectedAccount} />}

                </SpaceBetween>
            </Container>}

            </SpaceBetween>

        </ContentLayout>
        </MainLayout>
    );
}