import { FormField, DatePicker } from '@cloudscape-design/components'
import consoleOnlyLocally from '../../utils/consoleOnlyLocally';


export default function CustomInputDate({ label, value, setValue }) {

    function handleChange(detail) {
        consoleOnlyLocally(detail.value);
        setValue(detail.value);
    }

    return(
        <FormField 
            label={label} 
            stretch={true}
            constraintText="Format : Année/Mois/Jour"
        >
            <DatePicker 
                value={value} 
                onChange={({detail}) => handleChange(detail)}
                locale="fr-FR"
                placeholder="AAAA/MM/JJ"
                startOfWeek={0}
                fullWidth
            />
        </FormField>
    )
}