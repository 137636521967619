import { useNavigate } from "react-router-dom";
import { Box, Button, Container, SpaceBetween } from "@cloudscape-design/components";

import { createAsset, getAssetForAccountAndAvailableAsset, updateAsset } from "../../services/calls/asset";
import { updateAccount } from "../../services/calls/account";
import { updateOperation } from "../../services/calls/operation";

import consoleOnlyLocally from "../../utils/consoleOnlyLocally";
import { calculateFirstPaymentInfo, calculateMonthlyPayment } from "../../utils/calculatePayout";
import { toFormatCurrency } from "../../utils/toFormat";

import { STATUS_COMPLETED, STATUS_PROCESSING } from "../../constants/global";
import { OPTION_DISTRIBUTION } from "../../constants/global_lists";
import { getUTCDateFromISOStringDate, getUTCDateNow } from "../../utils/dateUtils";


export default function ValidateInvestment({ account, operation, setIsLoading }) {

    let navigate = useNavigate();

    async function handleDepositValidation() {

        setIsLoading(true);

        var tdy = getUTCDateNow();
        var newWaitingPeriod = tdy - getUTCDateFromISOStringDate(operation.dateReceived);
        newWaitingPeriod = newWaitingPeriod / (1000 * 60 * 60 * 24); // in days

        let newFirstpayment = 0;

        var operationData = {
            operationID: operation.operationID, 
            status: STATUS_PROCESSING,
            onDate: tdy.toISOString(),
            waitingPeriod: newWaitingPeriod,
            
            previousBalance: account.totalBalance,
            newBalance: account.totalBalance + operation.valueEur,
        }

        if(operation.investmentType.value === OPTION_DISTRIBUTION.value){

            const { endPeriod, duration, totalDays, totalRatio, firstPayment } = calculateFirstPaymentInfo(tdy, operation.valueEur, operation.annualYield);
            newFirstpayment = firstPayment;

            operation.dateEndFirstPeriod = getUTCDateFromISOStringDate(endPeriod);
            operation.durationFirstPeriod = duration;
            operation.totalDaysFirstPeriod = totalDays;
            operation.ratioFirstPeriod = totalRatio;
            operation.firstPayment = firstPayment;

        }
        consoleOnlyLocally(operationData);

        // 1. Update Investment with Status Processing
        await updateOperation(operationData);
        
        // 4. Get Asset for AvailableAsset
        const responseAsset = await getAssetForAccountAndAvailableAsset({
            accountID: operation.accountID,
            availableAssetID: operation.availableAssetID
        });
        const asset = responseAsset.asset;
        consoleOnlyLocally(asset);

        // 5. Create or Update Asset 
        if(asset === null) {
            await createAsset({
                accountID: operation.accountID,
                availableAssetID: operation.availableAssetID,
                investmentType: operation.investmentType,
                clientType: operation.clientType,
                label: operation.assetName,

                balance: operation.valueEur,
                deposited: operation.valueEur, 
                withdrawn: 0,
                earned: 0,

                annualYield: operation.annualYield,
                monthlyYield: operation.annualYield / 12,
            });
        }
        else{
            await updateAsset({
                assetID: asset.assetID,
                balance: asset.balance + operation.valueEur,
                deposited: asset.deposited + operation.valueEur,
            });
        }
        
        // 6. Update Account
        let newTotalBalance = account.totalBalance + operation.valueEur;

        if(operation.investmentType.value === OPTION_DISTRIBUTION.value){
            
            let newBalancePension = account.balancePension + operation.valueEur;

            let newPensionAnnualYield = (account.pensionAnnualYield * account.balancePension + operation.annualYield * operation.valueEur) / newBalancePension
            let newAvgAnnualYield = (account.avgAnnualYield * account.totalBalance + operation.annualYield * operation.valueEur) / newTotalBalance

            await updateAccount({
                accountID: operation.accountID,
                totalBalance: newTotalBalance,
                balancePension: newBalancePension,
                pensionDeposited: account.pensionDeposited + operation.valueEur,
                monthlyPayment: account.monthlyPayment + calculateMonthlyPayment(operation.valueEur, operation.annualYield),
                nextMonthlyPayment: account.nextMonthlyPayment + newFirstpayment,
                pensionAnnualYield: newPensionAnnualYield,
                avgAnnualYield: newAvgAnnualYield,
            });
        } 
        else{

            let newBalanceInvestment = account.balanceInvestment + operation.valueEur;

            let newInvestmentAnnualYield = (account.investmentAnnualYield * account.balanceInvestment + operation.annualYield * operation.valueEur) / newBalanceInvestment
            let newAvgAnnualYield = (account.avgAnnualYield * account.totalBalance + operation.annualYield * operation.valueEur) / newTotalBalance

            await updateAccount({
                accountID: operation.accountID,
                totalBalance: account.totalBalance + operation.valueEur,
                balanceInvestment: account.balanceInvestment + operation.valueEur,
                investmentDeposited: account.investmentDeposited + operation.valueEur,
                avgAnnualYield: newAvgAnnualYield,
                investmentAnnualYield: newInvestmentAnnualYield,
            });
        }


        // 7. Update Investment Status as COMPLETED 
        await updateOperation({
            operationID: operation.operationID, 
            status: STATUS_COMPLETED
        });

        navigate(`/comptes/compte/${account.accountID}`)
    }

    return(
    <Container>
        <SpaceBetween direction="vertical" size="m">
            <Box variant="h4">{"Valider Manuellement l'investissement"}</Box>
            <Box variant="p">{`Valider cette opération créditera le compte client de ${toFormatCurrency(operation.valueEur)}`}</Box>
            <div className="text-warning">La date du jour sera prise comme date de début d'Investissement. Le délai de carence sera donc aussi ajusté</div>
            <Button variant="primary" onClick={handleDepositValidation}>Valider</Button>
        </SpaceBetween>
    </Container>)
}