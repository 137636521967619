
import { 
    Wizard,
    Container,
    SpaceBetween,
    ColumnLayout,
    Grid
} from "@cloudscape-design/components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomInput from "../customs/CustomInput";
import { createFintech, getFintech, updateFintech } from "../../services/calls/fintech";
import LoadingPage from "../../loading/Loading";
import CustomSelect from "../customs/CustomSelect";
import { LIST_STATUS, OPTION_ACTIVE } from "../../constants/global_lists";
import ValueWithLabel from "../customs/ValueWithLabel";
import { toFormatCurrency, toFormatPercent } from "../../utils/toFormat";
import updatePerformance from "../performance/updatePerformance";


export default function FintechForm({ fintech, fintechs, info21yield }) {

    const [ activeStepIndex, setActiveStepIndex ] = useState(0);
    let [isLoading, setIsLoading] = useState(false);
    let [error, setError] = useState(null);

    let [name, setName] = useState(fintech ? fintech.name : "");
    let [loanAmount, setLoanAmount] = useState(fintech ? fintech.loanAmount : null);
    let [loanYield, setLoanYield] = useState(fintech ? fintech.loanYield * 100 : null);
    let [bankCollateral, setBankCollateral] = useState(fintech ? fintech.bankCollateral : null);
    let [rawYield, setRawYield] = useState(fintech ? fintech.rawYield * 100 : null);
    let [rawDefault, setRawDefault] = useState(fintech ? fintech.rawDefault * 100 : null);
    let [drawdownLimit, setDrawdownLimit] = useState(fintech ? fintech.drawdownLimit : null);
    let [status, setStatus] = useState(fintech ? fintech.status : OPTION_ACTIVE);

    const navigate = useNavigate();
    
    function validation() {

        if(name === "") {
            setError("Le nom de la fintech est requis");
            return false;
        }
        if(loanAmount === null) {
            setError("Le montant prêté est requis");
            return false;
        }
        if(loanYield === null) {
            setError("Le taux du prêt est requis");
            return false;
        }
        if(bankCollateral === null) {
            setError("Le montant en garantie est requis");
            return false;
        }
        if(rawYield === null) {
            setError("La performance brute est requise");
            return false;
        }
        if(rawDefault === null) {
            setError("Le taux de défaut est requis");
            return false;
        }
        if(drawdownLimit === null) {
            setError("La ligne de crédit est requise");
            return false;
        }
        if(status === null) {
            setError("Le statut de la relation est requis");
            return false;
        }

        return true;
    }

    async function handleSubmit() {

        setIsLoading(true);
        
        if(!validation()) {
            setIsLoading(false);
            return
        }

        const dataFintech = {
            name: name,
            loanAmount: parseFloat(loanAmount),
            loanYield: parseFloat(loanYield)/100,
            bankCollateral: parseFloat(bankCollateral),
            ratioCollateral: parseFloat(bankCollateral)/parseFloat(loanAmount),
            rawYield: parseFloat(rawYield)/100,
            rawDefault: parseFloat(rawDefault)/100,
            drawdownLimit: parseFloat(drawdownLimit),
            status: status
        };

        if(fintech) {
            dataFintech.fintechID = fintech.fintechID;
            await updateFintech(dataFintech);
            await updatePerformance(dataFintech, fintechs, info21yield);
        }
        else {
            var responseData = await createFintech(dataFintech);
            var theFintech = responseData.fintech;
            await updatePerformance(theFintech, fintechs, info21yield);
        }    
        
        navigate("/performance");
    }

    const Step1 = {
        title: "Performance de la Fintech",
        // info: <Link variant="info">Info</Link>,
        description: "Définissez les caractéristiques de la fintech que vous souhaitez ajouter",
        content: (
            <Container>
                <SpaceBetween direction="vertical" size="l">
                    <Grid gridDefinition={[
                        {colspan: { default: 12 } }, 
                        {colspan: { default: 12, xxs: 4 } }, 
                        {colspan: { default: 12, xxs: 4 } }, 
                        {colspan: { default: 12, xxs: 4 } }, 
                        {colspan: { default: 12, xxs: 4 } },
                        {colspan: { default: 12, xxs: 4 } }, 
                        {colspan: { default: 12, xxs: 4 } }, 
                        {colspan: { default: 12, xxs: 4 } }, 
                    ]}>
                        <CustomInput
                            label="Nom de la fintech"
                            value={name}
                            setValue={setName}
                            placeholder="Nom de la fintech"
                        />
                        <CustomInput
                            label="Montant Prêté (en €)"
                            value={loanAmount}
                            setValue={setLoanAmount}
                            placeholder="Montant total prêté à la fintech"
                        />
                        <CustomInput
                            label="Taux du Prêt (en %)"
                            value={loanYield}
                            setValue={setLoanYield}
                            placeholder="de 0 à 100%"
                        />
                        <CustomInput
                            label="Montant en garantie (en €)"
                            value={bankCollateral}
                            setValue={setBankCollateral}
                            placeholder="Sur le compte bancaire mis en garantie"
                        />
                        <CustomInput
                            label="Ligne de Crédit Max (en €)"
                            value={drawdownLimit}
                            setValue={setDrawdownLimit}
                            placeholder="Ligne de Crédit (Max)"
                        />
                        <CustomInput
                            label="Performance Brute (en %)"
                            value={rawYield}
                            setValue={setRawYield}
                            placeholder="de 0 à 100%"
                        />
                        <CustomInput
                            label="Taux de Défaut (en %)"
                            value={rawDefault}
                            setValue={setRawDefault}
                            placeholder="de 0 à 100%"
                        />
                        <CustomSelect
                            selectOptions={LIST_STATUS}
                            value={status}
                            setValue={setStatus}
                            name="Statut de la relation"
                        />
                    </Grid>
                </SpaceBetween>
            </Container>
        )
    };

    const StepValidation = {
        title: "Vérifier et Créer",
        content: (
            <SpaceBetween size="xs">
            <Container>
                <ColumnLayout
                    columns={3}
                    variant="text-grid"
                >
                    <ValueWithLabel label="Nom de la Fintech">
                        <div className="value-main">{name}</div>
                    </ValueWithLabel>

                    <ValueWithLabel label="Total prêté (en €)">
                        <div className="value-main">{toFormatCurrency(parseFloat(loanAmount))}</div>
                    </ValueWithLabel>

                    <ValueWithLabel label="Taux du prêt (en %)">
                        <div className="value-main">{toFormatPercent(parseFloat(loanYield)/100)}</div>
                    </ValueWithLabel>

                    <ValueWithLabel label="Montant Garantie Bancaire (en €)">
                        <div className="value-main">{toFormatCurrency(parseFloat(bankCollateral))}</div>
                    </ValueWithLabel>

                    <ValueWithLabel label="Ratio de Garantie Bancaire (en %)">
                        <div className="value-main">{toFormatPercent(parseFloat(bankCollateral)/parseFloat(loanAmount))}</div>
                    </ValueWithLabel>

                    <ValueWithLabel label="Ligne de Crédit (Max)">
                        <div className="value-main">{toFormatCurrency(parseFloat(drawdownLimit))}</div>
                    </ValueWithLabel>

                    <ValueWithLabel label="Performance Brute (en %)">
                        <div className="value-main">{toFormatPercent(parseFloat(rawYield)/100)}</div>
                    </ValueWithLabel>

                    <ValueWithLabel label="PAR90 (en %)">
                        <div className="value-main">{toFormatPercent(parseFloat(rawDefault)/100)}</div>
                    </ValueWithLabel>
                    
                    <ValueWithLabel label="Statut de la relation">
                        <div className="value-main">{status.label}</div>
                    </ValueWithLabel>

                </ColumnLayout>
            </Container>
            </SpaceBetween>
        )
    };

    if (isLoading) return <LoadingPage />;

    return (
        <Wizard
            i18nStrings={{
                stepNumberLabel: stepNumber => `Etape ${stepNumber}`,
                collapsedStepsLabel: (stepNumber, stepsCount) => `Etape ${stepNumber} de ${stepsCount}`,
                skipToButtonLabel: (step, stepNumber) => `Aller à l'étape ${step.title}`,
                navigationAriaLabel: "Etape",
                cancelButton: "Annuler",
                previousButton: "Précédent",
                nextButton: "Suivant",
                submitButton: "Valider la Fintech",
                optional: "optional"
            }}
            onNavigate={({ detail }) =>
                setActiveStepIndex(detail.requestedStepIndex)
            }
            activeStepIndex={activeStepIndex}
            allowSkipTo
            steps={[Step1, StepValidation]}
            onSubmit={() => handleSubmit()}
            onCancel={() => {navigate("/performance");}}
        />
    );
}