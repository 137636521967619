import { getInfo21yield } from "../services/calls/21yield";
import { getAllFintechs } from "../services/calls/fintech";


export async function fintechLoader({ request, params }) {

  const fintechID = params.fintechID;

  var dataFintechs = await getAllFintechs();
  const fintechs = dataFintechs.fintechs;
  
  // retrieve the fintech with the given fintechID
  const fintech = fintechs.find(f => f.fintechID === fintechID);

  var dataInfo21yield = await getInfo21yield();
  var info21yield = dataInfo21yield.info21yield;

  const breadcrumbs = [
    {
      text: 'Gestion du Fonds',
      href: '/',
    },
    {
      text: 'Fintech : ' + fintech.name + ' (' + fintech.fintechID + ')',
    }
  ];

  return { fintech, fintechs, info21yield, breadcrumbs };
}
  