import { postCalltoAPI, getCalltoAPI } from "../axiosCall";


// Method GET
export async function getAllOperations() {

  var response = await getCalltoAPI( "/get_all_operations");
  return response;
}


// Method POST
export async function createOperation(sendData) {
    
    var response = await postCalltoAPI( "/create_operation", sendData);
    return response;
}

export async function getOperation(sendData) {
    
  var response = await postCalltoAPI( "/get_operation", sendData);
  return response;
}


export async function updateOperation(sendData) {
    
    var response = await postCalltoAPI( "/update_operation", sendData);
    return response;
}

export async function getCashoutsForAsset(sendData) {
    
  var response = await postCalltoAPI( "/get_cashouts_for_asset", sendData);
  return response;
}

export async function getCashouts(sendData) {
    
  var response = await postCalltoAPI( "/get_cashouts", sendData);
  return response;
}


export async function deleteOperation(sendData) {
    
  var response = await postCalltoAPI( "/delete_operation", sendData);
  return response;
}

