import { redirect } from "react-router-dom";
import { getAccount, getAllAccounts } from "../services/calls/account";
import consoleOnlyLocally from "../utils/consoleOnlyLocally";


export async function addOperationLoader({ request, params }) {

  const breadcrumbs = [
    {
        text: 'Gestion des Comptes',
        href: '/',
    },
    {
        text: 'Operations',
        href: '/operations',
    },
    {
        text: 'Ajouter une Operation',
    },
  ];

  let accounts = [];
  let account = null;
  
  // If user specify accountID in the URL
  if(params.accountID ) {
    consoleOnlyLocally("option with accountID")
    consoleOnlyLocally('accountID: ' + params.accountID);

    // Get account from accountID
    const { account } = await getAccount({accountID: params.accountID});
    if(account === null){
      consoleOnlyLocally("Redirect to create account");
      return redirect("/comptes/ajouter");
    }
    // Either return the list of accounts or the account
    return { accounts, account, breadcrumbs };
  }

  // Otherwise, get all accounts
  else{
    consoleOnlyLocally("option without specific accountID")
    // Get all accounts
    const { accounts } = await getAllAccounts();
    if(accounts.length === 0) {
      consoleOnlyLocally("Redirect to create account");
      return redirect("/comptes/ajouter");
    }
    // Either return the list of accounts or the account
    return { accounts, account, breadcrumbs };
  }
  
  

}
  