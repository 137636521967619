import { Box, Button, Grid, Wizard } from '@cloudscape-design/components'
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

import CustomInput from '../customs/CustomInput';
import CustomSelect from '../customs/CustomSelect';
import CustomInputDate from '../customs/CustomInputDate';
import BoxInfo from '../customs/boxInfo';
import LoadingPage from '../../loading/Loading';
import consoleOnlyLocally from '../../utils/consoleOnlyLocally';
import { CATEGORY_PAYMENT, OPTION_PARTICULIER } from '../../constants/global_lists';
import { createOperation } from '../../services/calls/operation';
import { STATUS_COMPLETED } from '../../constants/global';
import { updateAccount } from '../../services/calls/account';
import { getUTCDateNow } from '../../utils/dateUtils';
import getPfu from '../../utils/getPfu';


export default function AddPayment({ account }) {

    let maxAmount = account.monthlyPayment;
    let { shouldApplyPFU, taxeRate } = getPfu(account);
    let pfuMaxAmount = maxAmount * (1- taxeRate);

    consoleOnlyLocally({ maxAmount, pfuMaxAmount });

    let [isLoading, setIsLoading] = useState(false);

    const [ activeStepIndex, setActiveStepIndex ] = useState(0);
    let [amountPayment, setAmountPayment] = useState(0);
    let [taxPaid, setTaxPaid] = useState(0); //
    let [onDate, setOnDate] = useState(getUTCDateNow().toISOString());

    let navigate = useNavigate();

    let handleChangeAmount = (value) => {
        setAmountPayment(value);

        var newTax = parseFloat(value) * taxeRate;
        setTaxPaid(newTax);
    }

    let handleChangeMaxAmount = (newMax) => {
        setAmountPayment(newMax);

        var total = parseFloat(newMax) / (1 - taxeRate);
        var tax = total * taxeRate;
        setTaxPaid(tax);
    }

    const Step1 = {
        title: "Paiement",
        description: "Indidquer les caractéristiques du paiement, pour un particulier, le montant max est de 70% du montant (si PFU est appliqué)",
        content: (
            <Grid
                gridDefinition={[
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: account.fiscalResidency ? 3 : 6}},
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 3}},
                ]}
            >
                <BoxInfo 
                    title="Nom du Compte"
                    value={account.name}
                    category='text'
                />
                <BoxInfo
                    title="Type de Compte"
                    value={account.compteType.label}
                    category='text'
                />
                {account.fiscalResidency && (<BoxInfo
                    title="Résidence Fiscale"
                    value={account.fiscalResidency.label}
                    category='text'
                />)}

                <BoxInfo
                    title="PFU"
                    value={shouldApplyPFU ? `Le PFU est appliqué: ${Math.floor(taxPaid)} €` : "Le PFU n'est pas appliqué"}
                    category='text'
                />
                
                <CustomInput
                    label="Montant du Paiement"
                    value={amountPayment}
                    setValue={handleChangeAmount}
                    placeholder="Montant"
                    type="number"
                />

                <CustomInputDate
                    label="Date du paiement"
                    value={onDate}
                    setValue={setOnDate}
                />

                <Box>
                    <div variant="description-medium" style={{marginBottom: "10px"}}>Appliquer le montant mensuel</div>
                    {(account.compteType.value === OPTION_PARTICULIER.value) && (account.fiscalResidency.value === "FR") ? (
                        <Button variant='primary' onClick={() => handleChangeMaxAmount(pfuMaxAmount)}>Montant PFU Max</Button>) : (
                        <Button variant='primary' onClick={() => handleChangeMaxAmount(maxAmount)}>Montant Max</Button> 
                    )}
                </Box>
            </Grid>
        )
    }

    const StepValidation = {
        title: "Validation",
        description: "Vérifiez les informations saisies",
        content: (
            <Grid
                gridDefinition={[
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: account.fiscalResidency ? 3 : 6}},
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 3}},
                ]}
            >
                
                <BoxInfo 
                    title="Nom du Compte"
                    value={account.name}
                    category='text'
                />
                <BoxInfo
                    title="Type de Compte"
                    value={account.compteType.label}
                    category='text'
                />
                {account.fiscalResidency && (<BoxInfo
                    title="Résidence Fiscale"
                    value={account.fiscalResidency.label}
                    category='text'
                />)}

                <BoxInfo
                    title="PFU"
                    value={shouldApplyPFU ? `Le PFU est appliqué: ${Math.floor(taxPaid)} €` : "Le PFU n'est pas appliqué"}
                    category='text'
                />
                
                <BoxInfo
                    title="Montant Paiement (net)"
                    value={parseFloat(amountPayment)}
                    category='currency'
                    decimals={0}
                />

                <BoxInfo
                    title="Total (brut)"
                    value={parseFloat(amountPayment) + taxPaid}
                    category='currency'
                    decimals={0}
                />
                
                <BoxInfo
                    title="Date du paiement"
                    value={onDate}
                    category='date'
                />

            </Grid>
        )
    }

    async function handleValidatePayment() {
        setIsLoading(true);
        
        let dataPayment = {
            accountID: account.accountID,
            accountName: account.name,
            askedByUserID: "admin",
            valueEur: parseFloat(amountPayment),
            onDate: onDate,
            previousBalance: account.totalBalance,
            newBalance: account.totalBalance,
            taxPaid: taxPaid,
            category: CATEGORY_PAYMENT,
            status: STATUS_COMPLETED
        }
        consoleOnlyLocally(dataPayment);
        await createOperation(dataPayment);

        let updatedAccountData = {
            accountID: account.accountID,
            totalPaid: account.totalPaid + parseFloat(amountPayment),
            taxPaid: account.taxPaid + taxPaid,
        }
        consoleOnlyLocally(updatedAccountData);
        await updateAccount(updatedAccountData);
        
        navigate(`/comptes/compte/${account.accountID}`);
        setIsLoading(false);
    }

    if (isLoading) {
        return <LoadingPage />
    }

    return(
        <Wizard
            i18nStrings={{
                stepNumberLabel: stepNumber => `Etape ${stepNumber}`,
                collapsedStepsLabel: (stepNumber, stepsCount) => `Etape ${stepNumber} de ${stepsCount}`,
                skipToButtonLabel: (step, stepNumber) => `Aller à l'étape ${step.title}`,
                navigationAriaLabel: "Etape",
                // cancelButton: "Annuler",
                previousButton: "Précédent",
                nextButton: "Suivant",
                submitButton: "Enregistrer le paiement",
                optional: "optional"
            }}
            onNavigate={({ detail }) =>
                setActiveStepIndex(detail.requestedStepIndex)
            }
            activeStepIndex={activeStepIndex}
            allowSkipTo
            steps={[Step1, StepValidation]}
            onSubmit={() => handleValidatePayment()}
            // onCancel={() => console.log("Cancel")}
        />
    )
}    
