import { 
    ContentLayout,
    SpaceBetween,
    Header,
    Button,
    Container

} from '@cloudscape-design/components';
import { useLoaderData } from 'react-router-dom';

import MainLayout from '../layout/MainLayout';
import consoleOnlyLocally from '../utils/consoleOnlyLocally';
import TableOperations from '../components/tableOperations';
import { useState } from 'react';
import AccountComponent from '../components/account/AccountComponent';
import AccountUpdateForm from '../components/account/AccountUpdateForm';


export default function Account() {

    const { account, operations, users, breadcrumbs } = useLoaderData();
    consoleOnlyLocally(account);
    consoleOnlyLocally(operations);

    let [openEdit, setOpenEdit] = useState(false);

    return (
        <MainLayout breadcrumbs={breadcrumbs} activeHref={"comptes"}>
        <SpaceBetween size='l'>
            <Container>
                <SpaceBetween direction="vertical" size="m">
                    <Header
                        variant='h2'
                        actions={
                            <Button 
                                variant={openEdit ? "normal" : 'primary'}
                                onClick={() => setOpenEdit(!openEdit)}
                            >
                                {openEdit ? "Annuler Modification" : "Modifier les Information"}
                            </Button>
                        }
                    >
                        Gestion du Compte de {account.name}
                    </Header>

                    {openEdit ? <AccountUpdateForm account={account} /> : <AccountComponent account={account} /> }

                </SpaceBetween>
            </Container>
        
            <TableOperations
                operations={operations}
                accountID={account.accountID}
            />

        </SpaceBetween>
        </MainLayout>
    );
}