import { useState } from "react"
import { Grid, Button } from '@cloudscape-design/components';
import CustomInput from "../customs/CustomInput";
import { createInfo21yield, updateInfo21yield } from "../../services/calls/21yield";
import LoadingPage from "../../loading/Loading";
import CustomSelect from "../customs/CustomSelect";
import { LIST_CATEGORY_AUTHORIZED, OPTION_AUTHORIZED } from "../../constants/global_lists";


export default function EditPerformance({ info21yield, setOpenEdit}) {
  
  let [isLoading, setIsLoading] = useState(false);
  let [totalLoaned, setTotalLoaned] = useState(info21yield ? info21yield.totalLoaned : "")
  let [rawYield, setRawYield] = useState(info21yield ? info21yield.rawYield: "")
  let [rawDefault, setRawDefault] = useState(info21yield ? info21yield.rawDefault: "")
  let [borrowerYield, setBorrowerYield] = useState(info21yield ? info21yield.borrowerYield: "")
  let [borrowerDefault, setBorrowerDefault] = useState(info21yield ? info21yield.borrowerDefault: "") 
  let [bankIban, setBankIban] = useState(info21yield ? info21yield.bankIban: "")
  let [bankBic, setBankBic] = useState(info21yield ? info21yield.bankBic: "")
  let [bankName, setBankName] = useState(info21yield ? info21yield.bankName: "")
  let [bankVerified, setBankVerified] = useState(info21yield ? info21yield.bankVerified: OPTION_AUTHORIZED)

  async function handleValidation() {
    setIsLoading(true);
    var ibanWithoutSpace = bankIban.replace(/\s/g, '');
    if(!info21yield){
      var data = {
        totalLoaned: parseFloat(totalLoaned),
        rawYield: parseFloat(rawYield),
        rawDefault: parseFloat(rawDefault),
        borrowerYield: parseFloat(borrowerYield),
        borrowerDefault: parseFloat(borrowerDefault),
        bankIban: ibanWithoutSpace,
        bankBic: bankBic,
        bankName: bankName,
        bankVerified: bankVerified
      }

      await createInfo21yield(data);

      window.location.reload();
    }
    else{
      var data = {
        infoID: info21yield.infoID,
        totalLoaned: parseFloat(totalLoaned),
        rawYield: parseFloat(rawYield),
        rawDefault: parseFloat(rawDefault),
        borrowerYield: parseFloat(borrowerYield),
        borrowerDefault: parseFloat(borrowerDefault),
        bankIban: ibanWithoutSpace,
        bankBic: bankBic,
        bankName: bankName,
        bankVerified: bankVerified
      }
      await updateInfo21yield(data);

      window.location.reload();
    }

    setOpenEdit(false);
  }

    
  if(isLoading) return <LoadingPage />
    
  return(
    <Grid gridDefinition={[
      { colspan: { default: 12, xxs: 3 } },
      { colspan: { default: 12, xxs: 2 } },
      { colspan: { default: 12, xxs: 2 } },
      { colspan: { default: 12, xxs: 2 } },
      { colspan: { default: 12, xxs: 2 } },
      { colspan: { default: 12, xxs: 3 } },
      { colspan: { default: 12, xxs: 3 } },
      { colspan: { default: 12, xxs: 3 } },
      { colspan: { default: 12, xxs: 3 } },
    ]}>
      <CustomInput
        label="Asset Under Management"
        placeholder="Montant (en €)" 
        value={totalLoaned}
        setValue={setTotalLoaned}
      />
      <CustomInput
        label="Rendement Brut 21yield"
        placeholder="Valeur décimale" 
        value={rawYield}
        setValue={setRawYield}
      />
      <CustomInput
        label="Taux de défaut des fintechs" 
        placeholder="Valeur décimale" 
        value={rawDefault}
        setValue={setRawDefault}
      />
      <CustomInput
        label="Rendement brut des fintechs" 
        placeholder="Valeur décimale" 
        value={borrowerYield}
        setValue={setBorrowerYield}
      />
      <CustomInput
        label="Taux de défaut des emprunteurs" 
        placeholder="Valeur décimale" 
        value={borrowerDefault}
        setValue={setBorrowerDefault}
      />
      <CustomInput
        label={"IBAN"}
        placeholder="IBAN"
        value={bankIban}
        setValue={setBankIban}
      />
      <CustomInput
        label={"BIC"}
        placeholder="BIC" 
        value={bankBic}
        setValue={setBankBic}
      />
      <CustomInput
        label={"Nom de la banque"}
        placeholder="Nom de la banque" 
        value={bankName}
        setValue={setBankName}
      />
      <CustomSelect
        selectOptions={LIST_CATEGORY_AUTHORIZED}
        value={bankVerified}
        setValue={setBankVerified}
        name="Bank Verified"
      />
      <Button variant="primary" iconName="upload" onClick={() => handleValidation()}>{info21yield ? "Save" : "Create"}</Button>
    </Grid>
  )
}