
import './tableFiltered.css';
import { Preferences } from './tableFilteredConfig';
import {
  Button,
  Pagination,
  SpaceBetween,
  Table,
  Header
} from '@cloudscape-design/components';


export default function TableSelectFilter({ items, saveWidths, collectionProps, paginationProps, preferences,
    setPreferences, columnDefinitions, displayOptions, titleHeader, description, routeDestination,  
    children }) {

    return (
        <Table
            {...collectionProps}
            enableKeyboardNavigation={true}
            columnDefinitions={columnDefinitions}
            columnDisplay={preferences.contentDisplay}
            items={items}
            // variant="full-page"
            stickyHeader={true}
            resizableColumns={true}
            onColumnWidthsChange={saveWidths}
            wrapLines={preferences.wrapLines}
            stripedRows={preferences.stripedRows}
            contentDensity={preferences.contentDensity}
            stickyColumns={preferences.stickyColumns}
            // selectionType="single"
            header={
                <Header
                    variant="h2"
                    description={description}
                    selectedItemsCount={collectionProps.selectedItems.length}
                    actions={
                        <SpaceBetween size="xs" direction="horizontal">
                            <Button variant='primary' href={routeDestination} iconName='add-plus'>Ajouter</Button>
                        </SpaceBetween>
                    }
                >
                    {titleHeader}
                </Header>
            }
            filter={
                <div className="input-container">
                    {children}
                </div>
            }
            pagination={<Pagination {...paginationProps} />}
            preferences={
                <Preferences
                    preferences={preferences}
                    setPreferences={setPreferences}
                    contentDisplayOptions={displayOptions}
                />
            }
        />
    );
}
