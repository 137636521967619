import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createOperation, updateOperation } from "../../services/calls/operation";
import consoleOnlyLocally from "../../utils/consoleOnlyLocally";
import { getAssetForAccountAndAvailableAsset, updateAsset } from "../../services/calls/asset";
import { updateAccount } from "../../services/calls/account";
import { calculateCompoundedValue, calculateLastPaymentInfo, calculateMonthlyPayment } from "../../utils/calculatePayout";
import { CATEGORY_PAYMENT, OPTION_CAPITALISATION, OPTION_DISTRIBUTION } from "../../constants/global_lists";
import { STATUS_COMPLETED, STATUS_PROCESSING } from "../../constants/global";
import { diffDays, getUTCDateFromISOStringDate, getUTCDateNow, setAsUTCString } from "../../utils/dateUtils";
import { toFormatCurrency, toFormatDate } from "../../utils/toFormat";
import { Button, Container, SpaceBetween, Toggle } from "@cloudscape-design/components";
import { Divider } from "@aws-amplify/ui-react";
import getPfu from "../../utils/getPfu";


export default function ValidateWithdrawal({ account, operation, setIsLoading }) {

    let plannedDuration = diffDays(getUTCDateNow(), getUTCDateFromISOStringDate(operation.onDate));
    let today = getUTCDateNow();
    let todayWithdrawalAmount = operation.valueEur;
    let { shouldApplyPFU, taxeRate } = getPfu(account);

    if(operation.investmentType.value === OPTION_CAPITALISATION.value) {
        todayWithdrawalAmount = calculateCompoundedValue(getUTCDateFromISOStringDate(operation.onDate), today, operation.annualYield, operation.valueEur);
    }

    let navigate = useNavigate();
    let [shouldAddPayment, setShouldAddPayment] = useState(true);
    
    async function handleCashoutValidation() {

        setIsLoading(true);

        //1. Get Asset for AvailableAsset
        const responseAsset = await getAssetForAccountAndAvailableAsset({
            accountID: operation.accountID,
            availableAssetID: operation.availableAssetID
        });
        const asset = responseAsset.asset;
        consoleOnlyLocally(asset);

        // 0. if the amount set is greater than current asset balance, set it to current asset balance
        // should never be true
        if (todayWithdrawalAmount > asset.balance) {
            consoleOnlyLocally("Amount Withdrawal is greater than balance");
        }

        var ratio_withdrawal = operation.valueEur / asset.balance
        consoleOnlyLocally(ratio_withdrawal);

        if (Math.ceil(ratio_withdrawal * 100) === 100) {
            ratio_withdrawal = 1;
            consoleOnlyLocally("Full Withdrawal");
        } else {
            consoleOnlyLocally("Partial Withdrawal");
        }

        let newTotalBalance = account.totalBalance - todayWithdrawalAmount;

        // Add the last payment info if it is a DISTRIBUTION
        if (operation.investmentType.value === OPTION_DISTRIBUTION.value) {

            // 1. Update Cashout
            var updated_data = {
                operationID: operation.operationID,
                status: STATUS_PROCESSING,
                valueEur: todayWithdrawalAmount,
                onDate: setAsUTCString(today),
                previousBalance: account.totalBalance,
                newBalance: account.totalBalance - todayWithdrawalAmount,
            }

            let {
                duration,
                totalDays,
                totalRatio,
                lastPayment
            } = calculateLastPaymentInfo(today, todayWithdrawalAmount, operation.annualYield);
            let lastPaid = lastPayment;
            let taxPaid = taxeRate * lastPayment;

            updated_data = {
                ...updated_data,
                duration: duration,
                totalDays: totalDays,
                totalRatio: totalRatio,
                lastPayment: lastPayment,
            }
            if (shouldAddPayment) {
                //Add Payment for the last payment
                let dataPayment = {
                    accountID: account.accountID,
                    accountName: account.name,
                    askedByUserID: "admin",
                    valueEur: lastPayment,
                    onDate: setAsUTCString(today),
                    previousBalance: parseFloat(account.totalBalance),
                    newBalance: parseFloat(account.totalBalance),
                    taxPaid: taxPaid,
                    category: CATEGORY_PAYMENT,
                    status: STATUS_COMPLETED
                }
                await createOperation(dataPayment);
            }

            await updateOperation(updated_data);

            // 2. Update Asset
            await updateAsset({
                assetID: asset.assetID,
                balance: asset.balance - todayWithdrawalAmount,
                withdrawn: asset.withdrawn + todayWithdrawalAmount
            });

            // 3. Update Account
            let newAvgAnnualYield;
            let newPensionAnnualYield;
            let newBalancePension = account.balancePension - todayWithdrawalAmount;

            if (newTotalBalance < 1) {
                newAvgAnnualYield = 0;
                newPensionAnnualYield = 0;
                newTotalBalance = 0;
                newBalancePension = 0;
            }

            if (newBalancePension < 1) {
                newPensionAnnualYield = 0;
                newBalancePension = 0;
            }

            if (newTotalBalance > 1 && newBalancePension > 1) {
                newPensionAnnualYield = (account.pensionAnnualYield * account.balancePension - operation.annualYield * todayWithdrawalAmount) / newBalancePension
                newAvgAnnualYield = (account.avgAnnualYield * account.totalBalance - operation.annualYield * todayWithdrawalAmount) / newTotalBalance
            }

            var reducePayment = calculateMonthlyPayment(todayWithdrawalAmount, operation.annualYield);
            var account_data = {
                accountID: operation.accountID,
                totalBalance: newTotalBalance,
                balancePension: newBalancePension,
                pensionWithdrawn: account.pensionWithdrawn + todayWithdrawalAmount,
                monthlyPayment: account.monthlyPayment - reducePayment,
                nextMonthlyPayment: account.nextMonthlyPayment - reducePayment,
                avgAnnualYield: newAvgAnnualYield,
                pensionAnnualYield: newPensionAnnualYield,
            }
            if (shouldAddPayment) {
                account_data = {
                    ...account_data,
                    totalPaid: account.totalPaid + lastPaid,
                    taxPaid: account.taxPaid + taxPaid
                }
            }

            await updateAccount(account_data);
        }

        // If cashout is from an investment
        else{

            var capital = account.investmentDeposited - account.investmentCapitalWithdrawn
            var interest = account.balanceInvestment - capital
            var interestWithdrawn = interest * ratio_withdrawal
            var capitalWithdrawn = todayWithdrawalAmount - interestWithdrawn
            var taxPaid = interestWithdrawn * taxeRate
            var netInterestWithdrawn = interestWithdrawn - taxPaid

            var updated_data = {
                operationID: operation.operationID,
                status: STATUS_PROCESSING,
                valueEur: todayWithdrawalAmount,
                onDate: setAsUTCString(today),
                previousBalance: account.totalBalance,
                newBalance: account.totalBalance - todayWithdrawalAmount,
                capitalWithdrawn: capitalWithdrawn,
                rawInterestWithdrawn: interestWithdrawn,
                netInterestWithdrawn: netInterestWithdrawn,
                taxPaid: taxPaid
            }
            updateOperation(updated_data);

            await updateAsset({
                assetID: asset.assetID,
                balance: asset.balance - todayWithdrawalAmount,
                withdrawn: asset.withdrawn + todayWithdrawalAmount,
                capitalWithdrawn: asset.capitalWithdrawn + capitalWithdrawn,
                rawInterestWithdrawn: asset.rawInterestWithdrawn + interestWithdrawn,
                netInterestWithdrawn: asset.netInterestWithdrawn + netInterestWithdrawn,
                taxPaid: asset.taxPaid + taxPaid
            });

            let newBalanceInvestment = account.balanceInvestment - todayWithdrawalAmount;
            let newAvgAnnualYield;
            let newInvestmentAnnualYield;

            if(newTotalBalance < 1) {
                newAvgAnnualYield = 0;
                newInvestmentAnnualYield = 0;
                newTotalBalance = 0;
                newBalanceInvestment = 0;
            }

            if(newBalanceInvestment < 1) {
                newInvestmentAnnualYield = 0;
                newBalanceInvestment = 0;
            }

            if(newTotalBalance > 1 && newBalanceInvestment > 1) {
                
                newInvestmentAnnualYield = (account.investmentAnnualYield * account.balanceInvestment - operation.annualYield * todayWithdrawalAmount) / newBalanceInvestment 
                newAvgAnnualYield = (account.avgAnnualYield * account.totalBalance - operation.annualYield * todayWithdrawalAmount) / newTotalBalance
            }

            await updateAccount({
                accountID: operation.accountID,
                totalBalance: newTotalBalance,
                balanceInvestment: newBalanceInvestment,
                investmentWithdrawn: account.investmentWithdrawn + todayWithdrawalAmount,
                investmentCapitalWithdrawn: account.investmentCapitalWithdrawn + capitalWithdrawn,
                investmentRawInterestWithdrawn: account.investmentRawInterestWithdrawn + interestWithdrawn,
                investmentNetInterestWithdrawn: account.investmentNetInterestWithdrawn + netInterestWithdrawn,
                investmentTaxPaid: account.investmentTaxPaid + taxPaid,
                avgAnnualYield: newAvgAnnualYield,
                investmentAnnualYield: newInvestmentAnnualYield,
            });
        }

        // 4. Update Status COMPLETED
        await updateOperation({
            operationID: operation.operationID,
            status: STATUS_COMPLETED
        });

        navigate(`/comptes/compte/${account.accountID}`);
    }

    return(
        <Container>
            <SpaceBetween direction="vertical" size="m">
                <div className='title-second'>{"Réaliser le retrait"}</div>
                <div className='text-warning'>{"Attention, l'opération bancaire doit être réalisée manuellement"}</div>
                <div className='description-small'>{`Cette opération est prévue dans ${plannedDuration} jours et réduira le compte client de ${toFormatCurrency(operation.valueEur)}`}</div>
                
                <Divider />

                <div className='subtitle-dark'>Vous pouvez procéder au retrait aujourd'hui</div>
                <div className='text-warning'>Changer la date à ajourd'hui modifiera le montant retirer à la valeur d'aujourd'hui</div>
                <div className='subtitle-dark'>{`Retrait de ${toFormatCurrency(todayWithdrawalAmount)} en date d'aujourd'hui ${toFormatDate(today)}`}</div>
               <Toggle 
                    label="Ajouter le paiement du dernier versement"
                    checked={shouldAddPayment}
                    onChange={() => setShouldAddPayment(!shouldAddPayment)}
                >
                    <div className='subtitle-dark'>{shouldAddPayment ? "Ajoute le paiement du solde de rente due" : "Ne pas ajouter de paiement de rente"}</div>
                </Toggle>
                
                <Button variant="primary" onClick={handleCashoutValidation}>Valider le retrait</Button>
                
            </SpaceBetween>
        </Container>
    )
}