import { ContentLayout, SpaceBetween, Container, Grid, Box } from '@cloudscape-design/components';
import { useLoaderData } from 'react-router-dom';
import { useState } from 'react';

import MainLayout from '../layout/MainLayout';
import BoxInfo from '../components/customs/boxInfo';

import { CATEGORY_CASHOUT, CATEGORY_INVESTMENT } from '../constants/global_lists';
import { STATUS_REQUESTED } from '../constants/global';

import LoadingPage from '../loading/Loading';
import AddInvestment from '../components/operation/addInvestment';
import AccountInfoCard from '../components/account/AccountInfoCard';
import ValidateInvestment from '../components/operation/validateInvestment';
import ValidateWithdrawal from '../components/operation/validateWithdrawal';
import consoleOnlyLocally from '../utils/consoleOnlyLocally';


export default function Operation() {

    const { operation, account, breadcrumbs } = useLoaderData();
    consoleOnlyLocally(operation);

    let [isLoading, setIsLoading] = useState(false);

    function OperationInfoComponent() {
        return(
        <SpaceBetween direction="vertical" size="m">
            <AccountInfoCard
                name={account.name}
                clientType={account.clientType}
                compteType={account.compteType}
                isAuthorized={account.isAuthorized}
            />

            <Container>
                <SpaceBetween direction="vertical" size="m">
                    <Box variant="h2">Operation {operation.category} Details</Box>
                    <Grid 
                        gridDefinition={[
                            {colspan: {default: 12, xs: operation.assetName ? 4 : 2}},
                            {colspan: {default: 12, xs: 2}},
                            {colspan: {default: 12, xs: 2}},
                            {colspan: {default: 12, xs: 2}},
                            {colspan: {default: 12, xs: 2}},
                            {colspan: {default: 12, xs: 2}},
                            {colspan: {default: 12, xs: 2}},
                            {colspan: {default: 12, xs: 2}},
                            {colspan: {default: 12, xs: 2}},
                            {colspan: {default: 12, xs: 2}},
                            {colspan: {default: 12, xs: 2}},
                            {colspan: {default: 12, xs: 2}},
                            {colspan: {default: 12, xs: 2}},
                        ]}
                    >
                        {operation.assetName && (<BoxInfo
                            title="Nom du Produit"
                            value={operation.assetName}
                            category='text'
                        />)}

                        <BoxInfo
                            title="Amount"
                            value={operation.valueEur}
                            category='currency'
                            decimals={0}
                        />
                        {operation.annualYield && (<BoxInfo
                            title="Taux Annuel"
                            value={operation.annualYield}
                            category='percent'
                        />)}
                        {operation.investmentType && (<BoxInfo
                            title="Type de Placement"
                            value={operation.investmentType.label}
                            category='text'
                        />)}

                        {operation.clientType && (<BoxInfo
                            title="Accessibilité"
                            value={operation.clientType.label}
                            category='text'
                        />)}

                        {operation.monthlyPayment && (
                            <BoxInfo
                                title="Mensualité"
                                value={operation.monthlyPayment}
                                category='currency'
                                decimals={0}
                            />
                        
                        )}

                        {operation.dateSigned && (
                        <BoxInfo
                            title="Date Signature"
                            value={operation.dateSigned}
                            category='date'
                        />)}
                        
                        {operation.dateReceived && (
                        <BoxInfo
                            title="Date Réception"
                            value={operation.dateReceived}
                            category='date'
                        />)}
                        
                        {operation.dateReceived && (
                        <BoxInfo
                            title="Délai de carence (jours)"
                            value={operation.waitingPeriod}
                            category='text'
                        />)}

                        <BoxInfo
                            title="Statut"
                            value={operation.status}
                            category='text'
                        />
                        
                        {operation.onDate && (
                        <BoxInfo 
                            title="Date" 
                            value={operation.onDate} 
                            category='date'
                        />)}

                        {operation.duration && (
                        <BoxInfo
                            title="Durée dernière période"
                            value={operation.duration}
                            category='text'
                        />)}

                        {operation.totalDays && (
                        <BoxInfo
                            title="Total Jours de la période"
                            value={operation.totalDays}
                            category='text'
                        />)}

                        {operation.totalRatio && (
                        <BoxInfo
                            title="Ratio"
                            value={operation.totalRatio}
                            category='percent'
                        />)}

                        {operation.lastPayment && (
                        <BoxInfo
                            title="Paiement Rente"
                            value={operation.lastPayment}
                            category='currency'
                            decimals={2}
                        />)}
                        
                        {operation.firstPayment && (
                        <BoxInfo
                            title="Prorata Initial (en €)"
                            value={operation.firstPayment}
                            category='currency'
                            decimals={2}
                        />)}
                        
                    </Grid>
                </SpaceBetween>
            </Container>

        </SpaceBetween>)
    }

    if(isLoading) return <LoadingPage />
     
    return (
        <MainLayout breadcrumbs={breadcrumbs} activeHref={`/operations`}>
            <ContentLayout>
                
                <SpaceBetween direction="vertical" size="m">

                    <OperationInfoComponent />

                    {operation.category === CATEGORY_INVESTMENT && operation.status === STATUS_REQUESTED && (
                        <ValidateInvestment account={account} operation={operation} setIsLoading={setIsLoading }/>
                    )}
                    {operation.category === CATEGORY_INVESTMENT && operation.status === STATUS_REQUESTED && (
                        <AddInvestment account={account} operation={operation} />
                    )}
                    
                    {operation.category === CATEGORY_CASHOUT && operation.status === STATUS_REQUESTED && (
                        <ValidateWithdrawal account={account} operation={operation} setIsLoading={setIsLoading} />
                    )}

                </SpaceBetween>
            </ContentLayout>
        </MainLayout>
    );
}