import { getAllOnAccount } from "../services/calls/account";


export async function accountLoader({ request, params }) {

    // Get the accountID from the URL
    const accountID = params.accountID;
    const data = await getAllOnAccount({accountID: accountID});

    const account = data.account;
    const users = data.users;
    const operations = data.operations;
    
    const breadcrumbs = [
        {
            text: 'Gestion des Comptes',
            href: '/',
        },
        {
            text: 'Comptes',
            href: "/comptes"
        },
        {
            text: "Compte: " + params.accountID
        }
    ];

    return { account, operations, users, breadcrumbs };
} 
  