import consoleOnlyLocally from "./consoleOnlyLocally";

export function prepareSelectOptions(field, defaultOption, data) {
    const optionSet = [];
    // Building a non redundant list of the field passed as parameter.

    data.forEach(item => {
        if (optionSet.indexOf(item[field]) === -1) {
            optionSet.push(item[field]);
        }
    });
    optionSet.sort();
    // consoleOnlyLocally({ optionSet });

    // The first element is the default one.
    const options = [defaultOption];

    // Adding the other element ot the list.
    optionSet.forEach((item, index) => {
        // consoleOnlyLocally({ item });

        var element = { label: item, value: (index + 1).toString() };
        options.push(element);
    });
    return options;
}


export function prepareSelectOptionsFromDictionary(defaultOption, data, key) {

    //  item has the following structure: {label: "Particulier", value: "1"}

    const filterSet = [];
    // The first element is the default one.
    const options = [defaultOption];

    // Building a non redundant list of the field passed as parameter.
    data.forEach(item => {
        // consoleOnlyLocally({ item });

        if (filterSet.indexOf(item[key].value) === -1) {
            filterSet.push(item[key].value);
            options.push(item[key]);
        }
    });
    // consoleOnlyLocally({ options });

    return options;
}
