import { toFormatDate } from "./toFormat";


export function getUTCDateNow() {  
    var tdy = new Date();
    return new Date(Date.UTC(tdy.getFullYear(), tdy.getMonth(), tdy.getDate()));
}
  
export function getUTCDateFromISOStringDate(stringDate) {
      var dt = new Date(stringDate);
      var utcDate = new Date(Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate()));
      return utcDate;
}

export function setAsUTCString(stringDate) {
    
    var utcDate = getUTCDateFromISOStringDate(stringDate);
    return utcDate.toISOString();

}

export const diffDays = (fromDate, toDate) => (toDate - fromDate) / (1000 * 60 * 60 * 24);

export function getEndNextQuarters(nbYears) {
    // Get the current date
    const currentDate = getUTCDateNow();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    // Create an array to store the dates of the last day of each quarter
    const quarterDates = [];

    // Check which quarters have not yet been completed in the current year
    if (currentMonth < 3) {
        // The first quarter has not yet been completed, so get the last day of the first quarter
        const q1 = new Date(Date.UTC(currentYear, 6, 0));
        quarterDates.push({indexQuarter: 1, year: currentYear, lastDay: q1, label: toFormatDate(q1)});
    }
    if (currentMonth < 6) {
        // The second quarter has not yet been completed, so get the last day of the second quarter
        const q2 = new Date(Date.UTC(currentYear, 9, 0));
        quarterDates.push({indexQuarter: 2, year: currentYear, lastDay: q2, label: toFormatDate(q2)});
    }
    if (currentMonth < 9) {
        // The third quarter has not yet been completed, so get the last day of the third quarter
        const q3 = new Date(Date.UTC(currentYear, 12, 0));
        quarterDates.push({indexQuarter: 3, year: currentYear, lastDay: q3, label: toFormatDate(q3)});
    }

    // Loop through the next two years
    for (let year = currentYear + 1; year <= currentYear + nbYears; year++) {
        // Get the last day of the first quarter
        let q1 = new Date(Date.UTC(year, 3, 0));
        quarterDates.push({indexQuarter: 1, year: year, lastDay: q1, label: toFormatDate(q1)});
        // Get the last day of the second quarter 
        let q2 = new Date(Date.UTC(year, 6, 0));
        quarterDates.push({indexQuarter: 2, year: year, lastDay: q2, label: toFormatDate(q2)});

        let q3 = new Date(Date.UTC(year, 9, 0));
        quarterDates.push({indexQuarter: 3, year: year, lastDay: q3, label: toFormatDate(q3)});

        const q4 = new Date(Date.UTC(year, 12, 0));
        quarterDates.push({indexQuarter: 4, year: year, lastDay: q4, label: toFormatDate(q4)});
    }

    return quarterDates;
}