import { 
    SpaceBetween,
    Grid
} from "@cloudscape-design/components";
import CustomInput from "../customs/CustomInput";
import AutocompleteComponent from "./autocomplete";
import CustomSelect from "../customs/CustomSelect";
import { useMemo } from "react";
import countryList from "react-select-country-list";


export default function AddressForm({ address, setAddress, addressComplement, setAddressComplement, city, setCity, zipCode, setZipCode, country, setCountry}) {
    
    const countryOptions = useMemo(() => countryList().getData(), []);

    const handleAutoCompleteSubmit = ({ address, addressComplement, zipCode, city, country }) => {
        setAddress(address);
        setCity(city);
        setCountry(country);
        setZipCode(zipCode);
        setAddressComplement(addressComplement);
    }

    return(
    <SpaceBetween direction="vertical" size="l">
        <AutocompleteComponent 
            onSubmit={handleAutoCompleteSubmit}
        />
        <Grid
            gridDefinition={[
                { colspan: { default: 12, xxs: 9}},
                { colspan: { default: 12, xxs: 3}},
                { colspan: { default: 12, xxs: 3}},
                { colspan: { default: 12, xxs: 3}},
                { colspan: { default: 12, xxs: 3}},
            ]}
            >
                <CustomInput
                    label="Adresse"
                    placeholder={"Adresse"}
                    value={address}
                    setValue={setAddress}
                />
                <CustomInput
                    label="Complément d'adresse"
                    placeholder={"Complément d'adresse"}
                    value={addressComplement}
                    setValue={setAddressComplement}
                />
                <CustomInput
                    label="Ville"
                    placeholder={"Ville"}
                    value={city}
                    setValue={setCity}
                />
                <CustomInput
                    label="Code Postal"
                    placeholder={"Code Postal"}
                    value={zipCode}
                    setValue={setZipCode}  
                />
                {/* <CustomInput
                    label="Pays"
                    placeholder={"Pays"}
                    value={country}
                    setValue={setCountry}
                /> */}
                <CustomSelect
                    selectOptions={countryOptions}
                    name="Pays"
                    value={country}
                    setValue={setCountry}
                />

            </Grid>
        </SpaceBetween>
    )
}