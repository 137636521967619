import { getAllAvailableAssets } from "../services/calls/available-asset";
import consoleOnlyLocally from "../utils/consoleOnlyLocally";
import protectedLoader from "./protectedLoader";


export async function availableAssetsLoader({ request }) {

    const redirection = protectedLoader({request});
    if (redirection) {
      return redirection;
    }
    
    var data = await getAllAvailableAssets();
    consoleOnlyLocally(data);
    const availableAssets = data.availableAssets;
    consoleOnlyLocally(availableAssets);
    
    const breadcrumbs = [
      {
        text: 'Gestion du Fonds',
        href: '/',
      },
      {
        text: "Produits Financiers",
      },
  ];

    return { availableAssets, breadcrumbs };
  }
  