import protectedLoader from "./protectedLoader";
import { getAllOperations } from "../services/calls/operation";


export async function operationsLoader({ request }) {

  const redirection = protectedLoader({request});
  if (redirection) {
    return redirection;
  }
  
  const { operations } = await getAllOperations();

  const breadcrumbs = [
    {
      text: 'Gestion des comptes',
      href: '/',
    },
    {
      text: "Operations",
    },
  ];

  return { operations, breadcrumbs };
}
  