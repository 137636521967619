import axios from 'axios';

import consoleOnlyLocally from '../utils/consoleOnlyLocally';
import { Amplify } from '@aws-amplify/core';

export const postCalltoAPI = async (route, data) => {

    var connexionAxios = await createAxiosInstance();
    var resp = await connexionAxios.post(route, data)

    consoleOnlyLocally(`Response from POST API : ${route}`);
    consoleOnlyLocally(resp.data);

    return resp.data
};

export const getCalltoAPI = async (route) => {
    
    var connexionAxios = await createAxiosInstance();
    var resp = await connexionAxios.get(route)

    consoleOnlyLocally(`Response from GET API : ${route}`);
    consoleOnlyLocally(resp.data);

    return resp.data
};

// function getValueContainingIdToken(obj) {
//     for (const key in obj) {
//       if (key.includes('idToken')) {
//         return obj[key];
//       }
//     }
//     return null; // If no key containing "idToken" is found
// }

async function createAxiosInstance(){

    const tokens = await Amplify.Auth.getTokens();
    let token = tokens.idToken.toString()

    var connexionAxios = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_API,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
    return connexionAxios;
}
