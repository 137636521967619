
export default function LoadingPage() {
    return (
    <div className="div-fullscreen">
        <div className="div-container">
            <h1 className="h1-title">Récupération des données</h1>
            <p>Consolidation des indicateurs et de la performance</p>
            <div className="spinner-container">
                <div className="loading-spinner"></div>
            </div>
        </div>
    </div>
    )
}