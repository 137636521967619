import { formatIban, toFormatCurrency, toFormatDate, toFormatPercent } from "../../utils/toFormat";
import "./boxInfo.css";


export default function BoxInfo({ title, value, category = "text", decimals = 2, isXL = false}) {

    function formatValue() {

        if (value === null || value === undefined) {
            return "";
        }

        if (category === "text") {
            return value;
        }
        if (category === "currency") {
            return toFormatCurrency(value, decimals);
        }
        if (category === "percent") {
            return toFormatPercent(value, decimals);
        }
        if (category === "date") {
            return toFormatDate(value);
        }
        if (category === "iban") {
            return formatIban(value);
        }
    }
    return(
        <div className="box-info">
            <div className={isXL ? "box-info-title-xl" : "box-info-title"}>{title}</div>
            <div className={isXL ? "box-info-value-xl" : "box-info-value"}>{formatValue()}</div>
        </div>
    )
}
