import { Box, Container, Link, SpaceBetween } from "@cloudscape-design/components";
import AccountInfoCard from "./AccountInfoCard";
import BalanceInfo from "./balanceInfo";
import AddressInfoCard from "./addressInfoCard";
import BankInfoCard from "./bankInfoCard";


export default function AccountComponent({ account }) {

    return (
    <SpaceBetween size='l'>
        <AccountInfoCard
            name={account.name}
            clientType={account.clientType}
            compteType={account.compteType}
            isAuthorized={account.isAuthorized}
        />

        <BalanceInfo account={account} />

        <AddressInfoCard
            address={account.address}
            addressComplement={account.addressComplement}
            city={account.city}
            zipCode={account.zipCode}
            country={account.country.label}
        />

        <BankInfoCard
            // title="Informations Bancaires"
            iban={account.bankIban}
            bic={account.bankBic}
            name={account.bankName}
            bankVerified={account.bankVerified}
        />

        <Container>
            <Box variant="h4">Coffre Fort Numérique</Box>
            <Link href={`https://eu-west-3.console.aws.amazon.com/s3/buckets/user-data-v3-${process.env.REACT_APP_ENV}?region=eu-west-3&bucketType=general&prefix=${account.accountID}/&showversions=false`} target="_blank">Lien vers l'espace documentaire S3</Link>
        </Container>
    </SpaceBetween>
    )
}