
import { 
    Wizard,
    Container,
    SpaceBetween,
    ColumnLayout,
    Box,
    Grid
} from "@cloudscape-design/components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LIST_CATEGORY_INVESTMENT, LIST_TYPE_CLIENT, OPTION_CAPITALISATION, OPTION_PROFESSIONEL } from "../constants/global_lists";
import CustomInput from "./customs/CustomInput";
import CustomSelect from "./customs/CustomSelect";
import { createAvailableAsset, updateAvailableAsset } from "../services/calls/available-asset";
import LoadingPage from "../loading/Loading";
import ValueWithLabel from "./customs/ValueWithLabel";
import { toFormatPercent } from "../utils/toFormat";


export default function AvailableAssetForm({ availableAsset }) {

    const [ activeStepIndex, setActiveStepIndex ] = useState(0);
    let [isLoading, setIsLoading] = useState(false);

    let [name, setName] = useState(availableAsset ? availableAsset.label : "");
    let [rendement, setRendement] = useState(availableAsset ? availableAsset.annualYield * 100 :"");
    let [investmentType, setInvestmentType] = useState(availableAsset ? availableAsset.investmentType : OPTION_CAPITALISATION)
    let [clientType, setClientType] = useState(availableAsset ? availableAsset.clientType : OPTION_PROFESSIONEL);
    let [waitingPeriod, setWaitingPeriod] = useState(availableAsset ? availableAsset.waitingPeriod : 30);

    const navigate = useNavigate();

    async function handleSubmit() {

        setIsLoading(true);
        
        if(availableAsset){
            const dataAvailableAsset = {
                availableAssetID: availableAsset.availableAssetID,
                label: name,
                annualYield: parseFloat(rendement) /100,
                monthlyYield: parseFloat(rendement) /100 / 12,
                investmentType: investmentType,
                clientType: clientType,
                waitingPeriod: waitingPeriod
            }
            await updateAvailableAsset(dataAvailableAsset);
            
        }
        else {
            const dataAvailableAsset = {
                label: name,
                annualYield: parseFloat(rendement) /100,
                monthlyYield: parseFloat(rendement) /100 / 12,
                investmentType: investmentType,
                clientType: clientType,
                waitingPeriod: waitingPeriod,
                // shareValue: 1
            }
            await createAvailableAsset(dataAvailableAsset);
        }

        navigate("/produits-financiers");
    }

    if(isLoading) return (<LoadingPage />);
    
    return (
        <Wizard
            i18nStrings={{
                stepNumberLabel: stepNumber => `Etape ${stepNumber}`,
                collapsedStepsLabel: (stepNumber, stepsCount) => `Etape ${stepNumber} de ${stepsCount}`,
                skipToButtonLabel: (step, stepNumber) => `Aller à l'étape ${step.title}`,
                navigationAriaLabel: "Etape",
                cancelButton: "Annuler",
                previousButton: "Précédent",
                nextButton: "Suivant",
                submitButton: "Valider le Produit",
                optional: "optional"
            }}
            onNavigate={({ detail }) =>
                setActiveStepIndex(detail.requestedStepIndex)
            }
            activeStepIndex={activeStepIndex}
            allowSkipTo
            steps={[
                {
                title: "Definir le type d'actif",
                // info: <Link variant="info">Info</Link>,
                description: "Définissez les caractéristiques de l'actif que vous souhaitez ajouter",
                content: (
                    <Container>
                        <SpaceBetween direction="vertical" size="l">
                            <Grid gridDefinition={[
                                    {colspan: { default: 12, xxs: 4 } }, 
                                    {colspan: { default: 12, xxs: 4 } }, 
                                    {colspan: { default: 12, xxs: 4 } },
                                    {colspan: { default: 12, xxs: 4 } },
                                    {colspan: { default: 12, xxs: 4 } },
                            ]}>
                                
                                <CustomInput
                                    label="Nom du Produit"
                                    placeholder="Nom du Produit"
                                    value={name}
                                    setValue={setName}
                                />

                                <CustomInput
                                    label="Rendement Annuel (en %)"
                                    placeholder="de 0 à 100%"
                                    value={rendement}
                                    setValue={setRendement}
                                />

                                <CustomSelect
                                    selectOptions={LIST_CATEGORY_INVESTMENT}
                                    value={investmentType}
                                    setValue={setInvestmentType}
                                    name="Catégorie d'Investissement"
                                />

                                <CustomInput
                                    label="Délai de carence (en jours)"
                                    placeholder="de 0 à 365 jours"
                                    value={waitingPeriod}
                                    setValue={setWaitingPeriod}
                                />
                                
                                <CustomSelect
                                    selectOptions={LIST_TYPE_CLIENT}
                                    value={clientType}
                                    setValue={setClientType}
                                    name="Type de Clients"
                                />

                            </Grid>
                        </SpaceBetween>
                    </Container>
                )
                },
                {
                title: "Vérifier et Créer",
                content: (
                    <SpaceBetween size="xs">
                    <Container>
                        <ColumnLayout
                            columns={3}
                            variant="text-grid"
                        >
                            <ValueWithLabel label="Nom du Produit Financier">
                                <div className="value-second">{name}</div>
                            </ValueWithLabel>

                            <ValueWithLabel label="Rendement Annuel">
                                <div className="value-second">{toFormatPercent(parseFloat(rendement)/100)}</div>
                            </ValueWithLabel>

                            <ValueWithLabel label="Délai de Carence">
                                <div className="value-second">{waitingPeriod} jours</div>
                            </ValueWithLabel>

                            <ValueWithLabel label="Catégorie d'Investissement">
                                <div className="value-second">{investmentType.label}</div>
                            </ValueWithLabel>

                            <ValueWithLabel label="Type de Clients">
                                <div className="value-second">{clientType.label}</div>
                            </ValueWithLabel>
                            
                        </ColumnLayout>
                    </Container>
                    </SpaceBetween>
                )
                }
            ]}
            onSubmit={() => handleSubmit()}
            onCancel={() => {navigate("/produits-financiers");}}
        />
    );
}