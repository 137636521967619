
import BreadcrumbGroup from '@cloudscape-design/components/breadcrumb-group';
import SideNavigation from '@cloudscape-design/components/side-navigation';
import TopNavigation from '@cloudscape-design/components/top-navigation';
import { AppLayout } from '@cloudscape-design/components';

// import '../../styles/base.scss';
// import '../../styles/top-navigation.scss';

import logo from "../logo192.png"
import { signOut } from 'aws-amplify/auth';
// import { Notifications } from '../commons/notifications';

const navItems = [
  {
    type: 'section-group',
    title: 'Gestion des Comptes',
    items: [
      { type: 'link', text: 'Comptes', href: '/comptes' },
      { type: 'link', text: 'Opérations', href: '/operations' },
      // { type: 'link', text: 'Rentes', href: '/rentes' },
    ],
  },
  {
    type: 'section-group',
    title: 'Gestion du Fonds',
    items: [
      { type: 'link', text: 'Performance', href: '/performance' },
      { type: "link", text: "Produits Financiers", href: '/produits-financiers', },
    ],
  },
];

const i18nStrings = {
  searchIconAriaLabel: 'Search',
  searchDismissIconAriaLabel: 'Close search',
  overflowMenuTriggerText: 'More',
  overflowMenuTitleText: 'All',
  overflowMenuBackIconAriaLabel: 'Back',
  overflowMenuDismissIconAriaLabel: 'Close menu',
};

// const profileActions = [
//   { id: 'profile', text: 'Profile' },
//   { id: 'preferences', text: 'Preferences' },
//   { id: 'security', text: 'Security' },
//   {
//     id: 'support-group',
//     text: 'Support',
//     items: [
//       {
//         id: 'documentation',
//         text: 'Documentation',
//         href: '#',
//         external: true,
//         externalIconAriaLabel: ' (opens in new tab)',
//       },
//       { id: 'feedback', text: 'Feedback', href: '#', external: true, externalIconAriaLabel: ' (opens in new tab)' },
//       { id: 'support', text: 'Customer support' },
//     ],
//   },
//   // { id: 'signout', text: 'Sign out', type: 'button',  onClick: () =>  signoutAction() },
// ];


export default function MainLayout({ children, breadcrumbs, activeHref }) {
//   const [searchValue, setSearchValue] = useState('');
  return (
    <>
      <TopNavigation
        i18nStrings={i18nStrings}
        identity={{
          href: '#',
          title: '21yield',
          logo: { src: logo, alt: '21yield' },
        }}
        // search={
        //   <Input
        //     ariaLabel="Input field"
        //     clearAriaLabel="Clear"
        //     value={searchValue}
        //     type="search"
        //     placeholder="Search"
        //     onChange={({ detail }) => setSearchValue(detail.value)}
        //   />
        // }
        utilities={[
          // {
          //   type: 'button',
          //   iconName: 'notification',
          //   ariaLabel: 'Notifications',
          //   badge: true,
          //   disableUtilityCollapse: true,
          // },
          // { 
          //   type: 'button', 
          //   iconName: 'settings', 
          //   title: 'Settings', 
          //   ariaLabel: 'Settings' 
          // },
          // {
          //   type: 'menu-dropdown',
          //   text: 'Customer name',
          //   description: 'customer@example.com',
          //   iconName: 'user-profile',
          //   items: profileActions,
          // },
          { 
            type: 'button', 
            iconName: 'close', 
            title: 'Signout', 
            text: 'Sign out', 
            ariaLabel: 'Signout',
            onClick: () => signOut()
          },
        ]}
      />
      <AppLayout
        stickyNotifications
        toolsHide
        navigation={<SideNavigation activeHref={activeHref} items={navItems} />}
        breadcrumbs={<BreadcrumbGroup items={breadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />}
        content={children}
        // notifications={<Notifications />}
      />
    </>
  );
}