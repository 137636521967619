import { getUTCDateFromISOStringDate } from "./dateUtils";


export function toFormatDate(value) {
    const date = getUTCDateFromISOStringDate(value);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
}

export function toFormatCurrency(value, decimals = 0) {
    if(value === null || value === undefined) return null;

    return value.toLocaleString('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: decimals,
    });
}

export function toFormatPercent(value, decimals = 2) {
    if(value === null || value === undefined) return null;

    return value.toLocaleString('fr-FR', {
        style: 'percent',
        currency: 'EUR',
        maximumFractionDigits: decimals,
    });
}

export function formatIban(iban) {

    if(iban === null || iban === undefined || iban === "") return "";
    return iban.replace(/(.{4})/g, '$1 ').trim();
}
