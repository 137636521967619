import { 
    Wizard,
    Container,
    SpaceBetween,
    ColumnLayout,
    Box,
    Grid
} from "@cloudscape-design/components";
import { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import countryList from "react-select-country-list";

import { 
    OPTION_AUTHORIZED, 
    OPTION_ENTREPRISE, 
    OPTION_PROFESSIONEL,
    OPTION_PARTICULIER,
    OPTION_UNAUTHORIZED} from '../../constants/global_lists';
import CustomInput from "../customs/CustomInput";
import SearchSiren from "./searchSiren";
import BoxInfo from "../customs/boxInfo";
import AccountInfoCard from "./AccountInfoCard";
import BankForm from "./bankForm";
import BankInfoCard from "./bankInfoCard";
import AddressForm from "./addressForm";
import AddressInfoCard from "./addressInfoCard";
import CustomInputDate from "../customs/CustomInputDate";
import AccountInfoForm from "./AccountInfoForm";
import LoadingPage from "../../loading/Loading";
import addAccountAction from "../../actions/addAccountAction";
import consoleOnlyLocally from "../../utils/consoleOnlyLocally";
import CustomSelect from "../customs/CustomSelect";
import CompanyInfoForm from "./CompanyInfoForm";
import { checkUsername } from "../../services/calls/username";
import { getUTCDateFromISOStringDate, getUTCDateNow } from "../../utils/dateUtils";


export default function AccountForm({ compteType }) {

    const options = useMemo(() => countryList().getData(), []);
    consoleOnlyLocally(options)

    const [ activeStepIndex, setActiveStepIndex ] = useState(0);
    const [ hasSearchSiren, setHasSearchSiren ] = useState(false);

    let [isLoading, setIsLoading] = useState(false)

    let [name, setName] = useState('')
    let [clientType, setClientType] = useState(OPTION_PROFESSIONEL)
    let [isAuthorized, setIsAuthorized] = useState(OPTION_AUTHORIZED)

    let [registrationNumber, setRegistrationNumber] = useState( '')
    let [registrationOffice, setRegistrationOffice] = useState('')
    let [companyLegalName, setCompanyLegalName] = useState('')
    let [codeNaf, setCodeNaf] = useState('')
    let [businessSector, setBusinessSector] = useState('')
    let [equity, setEquity] = useState('')
    let [dateCreated, setDateCreated] = useState('')

    let [address, setAddress] = useState('')
    let [addressComplement, setAddressComplement] = useState('')
    let [city, setCity] = useState('')
    let [zipCode, setZipCode] = useState('')
    let [country, setCountry] = useState('')

    let [username, setUsername] = useState('')
    let [password, setPassword] = useState('')
    let [email, setEmail] = useState('')
    let [firstname, setFirstname] = useState('')
    let [lastname, setLastname] = useState('')
    let [birthdate, setBirthdate] = useState('')
    let [fiscalResidency, setFiscalResidency] = useState({value: "FR", label: "France"})
    let [nationality, setNationality] = useState({value: "FR", label: "France"})
    
    let [bankIban, setBankIban] = useState('')
    let [bankBic, setBankBic] = useState('')
    let [bankName, setBankName] = useState('')
    let [bankVerified, setBankVerified] = useState(OPTION_UNAUTHORIZED)

    let [error, setError] = useState(null);

    const navigate = useNavigate();
    
    function resetCompany() {
        setRegistrationNumber('')
        setRegistrationOffice('')
        setCompanyLegalName('')
        setCodeNaf('')
        setBusinessSector('')
        setEquity('')
        setDateCreated('')
    }
    useEffect(() => {
        if (compteType.value === OPTION_PARTICULIER.value) {
            consoleOnlyLocally("Reset Company");
            resetCompany();
        }
    }, [compteType])

    const Step1 = {
        title: "Définir le Compte Client",
        // info: <Link variant="info">Info</Link>,
        description: "Spécifier les caractéristiques du compte client",
        content: (
            <Container>
                <SpaceBetween direction="vertical" size="l">
                    <AccountInfoForm
                        name={name}
                        setName={setName}
                        clientType={clientType}
                        setClientType={setClientType}
                        isAuthorized={isAuthorized}
                        setIsAuthorized={setIsAuthorized}
                    />

                </SpaceBetween>
            </Container>
        )
    }
    
    const Step2 = {
        title: "SIREN",
        // info: <Link variant="info">Info</Link>,
        description: "Récupérer les informations de l'entreprise via son SIREN",
        content: (
            <Container>
                <SpaceBetween direction="vertical" size="l">
                    <SearchSiren 
                        setRegistrationNumber={setRegistrationNumber}
                        setRegistrationOffice={setRegistrationOffice}
                        setCompanyLegalName={setCompanyLegalName}
                        setCodeNaf={setCodeNaf}
                        setBusinessSector={setBusinessSector}
                        setEquity={setEquity}
                        setDateCreated={setDateCreated}
                        setAddress={setAddress}
                        setAddressComplement={setAddressComplement}
                        setCity={setCity}
                        setZipCode={setZipCode}
                        setCountry={setCountry}
                        setHasSearchSiren={setHasSearchSiren}
                    />
                    
                    <CompanyInfoForm
                        companyLegalName={companyLegalName}
                        setCompanyLegalName={setCompanyLegalName}
                        registrationNumber={registrationNumber}
                        setRegistrationNumber={setRegistrationNumber}
                        registrationOffice={registrationOffice}
                        setRegistrationOffice={setRegistrationOffice}
                        codeNaf={codeNaf}
                        setCodeNaf={setCodeNaf}
                        businessSector={businessSector}
                        setBusinessSector={setBusinessSector}
                        equity={equity}
                        setEquity={setEquity}
                        dateCreated={dateCreated}
                        setDateCreated={setDateCreated}
                        hasSearchSiren={hasSearchSiren}
                    />

                </SpaceBetween>
            </Container>
        )
    }

    const Step3 = {
        title: "Adresse",
        // info: <Link variant="info">Info</Link>,
        description: "Récupérer l'adresse de l'API Google",
        content: (
            <AddressForm
                address={address}
                setAddress={setAddress}
                addressComplement={addressComplement}
                setAddressComplement={setAddressComplement}
                city={city}
                setCity={setCity}
                zipCode={zipCode}
                setZipCode={setZipCode}
                country={country}
                setCountry={setCountry}
            />
        )
    }

    const Step4 = {
        title: "Personne Physique",
        // info: <Link variant="info">Info</Link>,
        description: "Renseigner les informations de la personne physique en charge du compte client",
        content: (
            <Container>
                <SpaceBetween direction="vertical" size="l">
                    <Grid 
                        gridDefinition={[
                            { colspan: { default: 12, xxs: 3}},
                            { colspan: { default: 12, xxs: 3}},
                            { colspan: { default: 12, xxs: 3}},
                            { colspan: { default: 12, xxs: 3}},
                            { colspan: { default: 12, xxs: 3}},
                            { colspan: { default: 12, xxs: 3}},
                            { colspan: { default: 12, xxs: 6}},
                            { colspan: { default: 12, xxs: 6}},
                        ]}
                    >
                        <CustomInput
                            label="Prénom"
                            placeholder="Prénom"
                            value={firstname}
                            setValue={setFirstname}
                        />
                        <CustomInput
                            label="Nom"
                            placeholder="Nom"
                            value={lastname}
                            setValue={setLastname}
                        />
                        <CustomInputDate
                            label="Date de Naissance"
                            value={birthdate}
                            setValue={setBirthdate}
                        />
                        <CustomSelect
                            selectOptions={options}
                            value={fiscalResidency}
                            setValue={setFiscalResidency}
                            name="Résidence Fiscale"
                        />
                        
                        <CustomSelect
                            selectOptions={options}
                            name="Nationalité"
                            value={nationality}
                            setValue={setNationality}
                        />
                        <CustomInput
                            label="Username"
                            placeholder="Username"
                            value={username}
                            setValue={setUsername}
                        />
                        <CustomInput
                            label="Email"
                            placeholder="Email"
                            value={email}
                            setValue={setEmail}
                            type="email"
                        />
                        <CustomInput
                            label="Password"
                            placeholder="Password, 20 characters minimum, 1 uppercase, 1 lowercase, 1 number"
                            value={password}
                            setValue={setPassword}
                            type="password"
                        />
                    </Grid>
                </SpaceBetween>
            </Container>
        )
    }
    
    const Step5 = {
        title: "Compte Bancaire",
        // info: <Link variant="info">Info</Link>,
        description: "Renseigner le compte bancaire du compte, si l'IBAN est déjà connu",
        content: (
            <BankForm
                bankIban={bankIban}
                setBankIban={setBankIban}
                bankBic={bankBic}
                setBankBic={setBankBic}
                bankName={bankName}
                setBankName={setBankName}
                bankVerified={bankVerified}
                setBankVerified={setBankVerified}
            />
        ),          
        isOptional: true
    }

    const StepValidation = {
        title: "Vérifier et Créer",
        content: (
            <SpaceBetween size="xs">
                
                <AccountInfoCard    
                    name={name}
                    clientType={clientType}
                    compteType={compteType}
                    isAuthorized={isAuthorized}
                />

                {compteType.label === OPTION_ENTREPRISE.label && <Container>
                    <ColumnLayout
                        columns={3}
                        variant="text-grid"
                    >
                        <BoxInfo title="Nom Légal" value={companyLegalName} />
                        <BoxInfo title="SIREN" value={registrationNumber} />
                        <BoxInfo title="Greffe" value={registrationOffice} />
                        <BoxInfo title="Code NAF" value={codeNaf} />
                        <BoxInfo title="Secteur d'Activité" value={businessSector} />
                        <BoxInfo title="Capital" value={equity} />
                        <BoxInfo title="Date de Création" value={dateCreated} />
                        
                    </ColumnLayout>
                </Container>}

                <AddressInfoCard
                    address={address}
                    addressComplement={addressComplement}
                    city={city}
                    zipCode={zipCode}
                    country={country.label}
                />
                
                <Container>
                    <ColumnLayout
                        columns={3}
                        variant="text-grid"
                    >
                        <Box variant="h4">Utilisateur</Box>
                        <BoxInfo title="Prénom" value={firstname} />
                        <BoxInfo title="Nom" value={lastname} />
                        <BoxInfo title="Date de Naissance" value={birthdate} />
                        <BoxInfo title="Nationalité" value={nationality.label} />
                        <BoxInfo title="Résidence Fiscale" value={fiscalResidency.label} />
                        <BoxInfo title="Username" value={username} />
                        <BoxInfo title="Email" value={email} />
                        <BoxInfo title="Password" value={password} />
                        
                    </ColumnLayout>
                </Container>

                {bankIban && (
                    <BankInfoCard
                        title={"Informations Bancaires"}
                        iban={bankIban}
                        bic={bankBic}
                        name={bankName}
                        bankVerified={bankVerified}
                    />
                )}
            </SpaceBetween>
        )
    }

    const steps_Entreprise = [ Step1, Step2, Step3, Step4, Step5, StepValidation ]
    const steps_Indivdual = [ Step1, Step3, Step4, Step5, StepValidation ]

    async function validationData() {

        if (name === '') {
            setError("Le nom du compte est obligatoire");
            return false;
        }
        if (compteType.value === OPTION_ENTREPRISE.value && registrationNumber === '') {
            setError("Le numéro de SIREN est obligatoire");
            return false;
        }
        
        if (compteType.value === OPTION_ENTREPRISE.value && registrationOffice === '') {
            setError("Le greffe est obligatoire");
            return false;
        }

        if (compteType.value === OPTION_ENTREPRISE.value && companyLegalName === '') {
            setError("Le nom légal de l'entreprise est obligatoire");
            return false;
        }

        if (compteType.value === OPTION_ENTREPRISE.value && codeNaf === '') {
            setError("Le code NAF est obligatoire");
            return false;
        }

        if (compteType.value === OPTION_ENTREPRISE.value && businessSector === '') {
            setError("Le secteur d'activité est obligatoire");
            return false;
        }

        if (compteType.value === OPTION_ENTREPRISE.value && equity === '') {
            setError("Le capital est obligatoire");
            return false;
        }

        if (compteType.value === OPTION_ENTREPRISE.value && dateCreated === '') {
            setError("La date de création est obligatoire");
            return false;
        }

        if (address === '') {
            setError("L'adresse est obligatoire");
            return false;
        }

        if (city === '') {
            setError("La ville est obligatoire");
            return false;
        }

        if (zipCode === '') {
            setError("Le code postal est obligatoire");
            return false;
        }

        if (country === '') {
            setError("Le pays est obligatoire");
            return false;
        }

        // if birthday is less than 18 years old
        const today = getUTCDateNow();
        const birthdateDate = getUTCDateFromISOStringDate(birthdate);
        const age = (today - birthdateDate) / (1000 * 60 * 60 * 24 * 365);
        consoleOnlyLocally(age);
        
        if (age < 18) {
            setError("L'utilisateur doit être majeur pour créer un compte");
            return false;
        }

        if (username === '') {
            setError("Le nom d'utilisateur est obligatoire");
            return false;
        }

        // Verify that the username doesnt exist in the cognito userpool
        var data = await checkUsername({username: username});
        if (!data.available){
            // join all the names string in data.usernames to display them in the error message
            const listUsernames = data.usernames.join(", ");
            setError(`Le nom d'utilisateur ${username} est déjà pris. Les utilisateurs existants sont: ${listUsernames}`);
            return false;
        }

        if (password === '') {
            setError("Le mot de passe est obligatoire");
            return false;
        }

        if (password.length < 20) {
            setError("Le mot de passe doit contenir au moins 20 caractères");
            return false;
        }

        if  (password.search(/[a-z]/) < 0) {
            setError("Le mot de passe doit contenir au moins une lettre minuscule");
            return false;
        }

        if  (password.search(/[A-Z]/) < 0) {
            setError("Le mot de passe doit contenir au moins une lettre majuscule");
            return false;
        }

        if  (password.search(/[0-9]/) < 0) {
            setError("Le mot de passe doit contenir au moins un chiffre");
            return false;
        }

        return true;
    }

    async function handleAddAccount() {
        
        setIsLoading(true);
        const isDataValid = await validationData();

        if (isDataValid) {

            consoleOnlyLocally("Data is valid, adding account")
            await addAccountAction({ 
                data : {
                    name,
                    compteType,
                    clientType,
                    isAuthorized,
    
                    registrationNumber,
                    registrationOffice,
                    companyLegalName,
                    codeNaf,
                    businessSector,
                    equity,
                    dateCreated,
    
                    address,
                    addressComplement,
                    city,
                    zipCode,
                    country,
    
                    username,
                    password,
                    email,
                    firstname,
                    lastname,
                    birthdate,
                    nationality,
                    fiscalResidency,
    
                    bankIban,
                    bankBic,
                    bankName,
                    bankVerified
                }
            });
            navigate("/comptes");   
        }
        
        setIsLoading(false);
    }

    if(isLoading) return <LoadingPage />

    return (
    <>
        {error && <div style={{color:"red", fontWeight:"500", paddingBottom:"20px"}}>Erreur: {error}</div>}
        <Wizard
            i18nStrings={{
                stepNumberLabel: stepNumber => `Etape ${stepNumber}`,
                collapsedStepsLabel: (stepNumber, stepsCount) => `Etape ${stepNumber} de ${stepsCount}`,
                // skipToButtonLabel: (step, stepNumber) => `Aller à ${step.title}`,
                navigationAriaLabel: "Etape",
                cancelButton: "Annuler",
                previousButton: "Précédent",
                nextButton: "Suivant",
                submitButton: "Créer le Compte Client",
                optional: "optionnel"
            }}
            onNavigate={({ detail }) =>
                setActiveStepIndex(detail.requestedStepIndex)
            }
            activeStepIndex={activeStepIndex}
            allowSkipTo
            steps={compteType.label === OPTION_ENTREPRISE.label ? steps_Entreprise : steps_Indivdual}
            onSubmit={() => { handleAddAccount(); }}
            onCancel={() => { navigate("/comptes"); }}
        />    
    </>
    );
}