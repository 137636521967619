import { Grid, SpaceBetween, Toggle, Wizard } from '@cloudscape-design/components'
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import CustomInput from '../customs/CustomInput';
import CustomSelect from '../customs/CustomSelect';
import CustomInputDate from '../customs/CustomInputDate';
import BoxInfo from '../customs/boxInfo';
import LoadingPage from '../../loading/Loading';
import { createOperation, updateOperation } from '../../services/calls/operation';
import { STATUS_REQUESTED } from '../../constants/global';
import { CATEGORY_INVESTMENT, OPTION_DISTRIBUTION } from '../../constants/global_lists';
import { getAllAvailableAssets } from '../../services/calls/available-asset';
import { calculateFirstPaymentInfo } from '../../utils/calculatePayout';
import { getUTCDateFromISOStringDate, getUTCDateNow } from '../../utils/dateUtils';


export default function AddInvestment({ account, operation }) {

    let [isLoading, setIsLoading] = useState(true);

    let [allAvailableAssets, setAllAvailableAssets] = useState([])

    let [selectedAvailableAsset, setSelectedAvailableAsset] = useState(null);
    let [amount, setAmount] = useState(operation ? operation.valueEur : 10000 ); 

    let [dateSigned, setDateSigned] = useState(operation ? operation.dateSigned : getUTCDateNow().toISOString());

    let [isReceived, setIsReceived] = useState((operation && operation.dateReceived) ? true : false);
    let [dateReceived, setDateReceived] = useState((operation && operation.dateReceived) ? operation.dateReceived : getUTCDateNow().toISOString());
    let [waitingPeriod, setWaitingPeriod] = useState((operation && operation.waitingPeriod) ? operation.waitingPeriod : 0);
    let [onDate, setOnDate] = useState((operation && operation.onDate) ? operation.onDate : getUTCDateNow().toISOString());

    let [endPeriod, setEndPeriod] = useState((operation && operation.endPeriod) ? getUTCDateFromISOStringDate(operation.endPeriod) : null);
    let [duration, setDuration] = useState((operation && operation.duration) ? operation.duration : null);
    let [totalDays, setTotalDays] = useState((operation && operation.totalDays) ? operation.totalDays : null);
    let [totalRatio, setTotalRatio] = useState((operation && operation.totalRatio) ? operation.totalRatio : null);
    let [firstPayment, setFirstPayment] = useState((operation && operation.firstPayment) ? operation.firstPayment : null);

    const [ activeStepIndex, setActiveStepIndex ] = useState(0);

    let navigate = useNavigate();

    async function loadAvailableAssets() {

        var data = await getAllAvailableAssets();
        setAllAvailableAssets(data.availableAssets);

        let theAsset;
        if(operation){
            theAsset = data.availableAssets.find(asset => asset.availableAssetID === operation.availableAssetID);
        }
        else{
            theAsset = data.availableAssets[0];
        }
        
        setSelectedAvailableAsset(theAsset);

        if(operation && operation.onDate){
            setWaitingPeriod(operation.waitingPeriod);
            setOnDate(operation.onDate);
        }
        else{
            setWaitingPeriod(theAsset.waitingPeriod);
            // add the waiting period to the date received to get the date of date of investment
            var date = getUTCDateNow();
            date.setDate(date.getDate() + theAsset.waitingPeriod);
            setOnDate(date.toISOString());
        }
        setIsLoading(false);
    }
    useEffect(() => {
        loadAvailableAssets();
    }, []);


    // function that checks if the selectedAvailableAsset is a capitalisation or distribution
    // if it is a distribution it will update the date of first monthly payment and calculate the ratio of days for the month 
    function updateFirstPaymentInfo(theInvestmentDate, theAmount, received){
        
        if(!received) return;

        if(selectedAvailableAsset.investmentType.value === OPTION_DISTRIBUTION.value){
      
            const { endPeriod, duration, totalDays, totalRatio, firstPayment } = calculateFirstPaymentInfo(theInvestmentDate, theAmount, selectedAvailableAsset.annualYield);
            
            setEndPeriod(endPeriod.toISOString());
            setDuration(duration);
            setTotalDays(totalDays);
            setTotalRatio(totalRatio);
            setFirstPayment(firstPayment);
        }
        else{
            setEndPeriod(null);
            setDuration(null);
            setTotalDays(null);
            setTotalRatio(null);
            setFirstPayment(null);
        }
    } 
    useEffect(() => {
        if(selectedAvailableAsset){
            updateFirstPaymentInfo(onDate, amount, isReceived);
            handleChangeWaitingPeriod((operation && operation.waitingPeriod) ? operation.waitingPeriod : selectedAvailableAsset.waitingPeriod);
        }
    }, [selectedAvailableAsset]);

    function handleChangeWaitingPeriod(value) {

        if(undefined === value || null === value || "" === value){
            value = 0;
        }

        setWaitingPeriod(value);
        var date = getUTCDateFromISOStringDate(dateReceived);
        date.setDate(date.getDate() + parseInt(value));
        var theInvestmentDate = date.toISOString();

        setOnDate(theInvestmentDate);
        updateFirstPaymentInfo(theInvestmentDate, amount, isReceived);
    }

    function handleChangeAmount(value) {
        setAmount(value);
        updateFirstPaymentInfo(onDate, value, isReceived)
    }

    function handleChangeDateReceived(value) {

        setDateReceived(value);

        var date = getUTCDateFromISOStringDate(value);
        date.setDate(date.getDate() + parseInt(waitingPeriod));
        var theInvestmentDate = date.toISOString();
        setOnDate(theInvestmentDate);
        updateFirstPaymentInfo(theInvestmentDate, amount, isReceived);
    }

    function handleChangeChecked(value) {
        setIsReceived(value);
        if(!value){
            setDateReceived(getUTCDateNow().toISOString());
            setWaitingPeriod(0);
            setOnDate(getUTCDateNow().toISOString());
            setEndPeriod(null);
            setDuration(null);
            setTotalDays(null);
            setTotalRatio(null);
            setFirstPayment(null);
        }
        else{
            updateFirstPaymentInfo(onDate, amount, value);
        }
    }

    const Step1 = {
        title: "Souscription",
        description: "Définissez les caractéristiques de l'investissement à ajouter",
        content: (
            <Grid
                gridDefinition={[
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 5}},
                    {colspan: {default: 12, xs: 4}},
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 3}},
                ]}
            >
                <CustomInput
                    label="Montant (en €)"
                    value={amount}
                    setValue={handleChangeAmount}
                    placeholder="Montant (en €)"
                    type="number"
                />
                <CustomSelect
                    selectOptions={allAvailableAssets}
                    value={selectedAvailableAsset}
                    setValue={setSelectedAvailableAsset}
                    name="Nom du produit souscrit"
                />
                <CustomInputDate
                    label="Date Signature du Bulletin"
                    value={dateSigned}
                    setValue={setDateSigned}
                />
                <SpaceBetween size="s">
                    <div className='description'>Valider si le virement est déjà reçu</div>
                    <Toggle
                        onChange={({ detail }) => handleChangeChecked(detail.checked)}
                        checked={isReceived}
                    >
                        {isReceived ? "Virement Reçu" : "Virement Non Reçu"}
                    </Toggle>
                </SpaceBetween>

                {isReceived && (
                    <CustomInputDate
                        label="Date de Réception"
                        value={dateReceived}
                        setValue={handleChangeDateReceived}
                    />
                )}

                {isReceived && (
                    <CustomInput
                        label="Délai de carence (en jours)"
                        value={waitingPeriod}
                        setValue={handleChangeWaitingPeriod}
                        placeholder="Délai de carence (en jours)"
                        type='number'
                    />
                )}

                {isReceived && (
                    <BoxInfo
                        title="Date d'Investissement"
                        value={onDate}
                        category='date'
                    />
                )}

            </Grid>
        )
    }

    const StepValidation = {
        title: "Validation",
        description: "Vérifiez les informations saisies",
        content: (
            <Grid
                gridDefinition={[
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 6}},
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 3}},
                ]}
            >
                <BoxInfo
                    title="Montant du Dépôt"
                    value={parseFloat(amount)}
                    category='currency'
                    decimals={0}
                />
                <BoxInfo
                    title="Produit souscrit"
                    value={selectedAvailableAsset ? selectedAvailableAsset.label : ""}
                />
                <BoxInfo
                    title="Date de Signature"
                    value={dateSigned}
                    category='date'
                />
                
                {isReceived && (
                    <BoxInfo
                        title="Date de Réception"
                        value={dateReceived}
                        category='date'
                    />
                )}

                {isReceived && (
                    <BoxInfo
                        title="Délai de carence (en jours)"
                        value={waitingPeriod}
                    />
                )}
                
                {isReceived && (
                    <BoxInfo
                        title="Date d'Investissement"
                        value={onDate}
                        category='date'
                    />
                )}

                {endPeriod && (
                    <BoxInfo
                        title="Date Clôture (1ère rente)"
                        value={endPeriod}
                        category='date'
                    />
                )}

                {duration && (
                    <BoxInfo
                        title="Durée de la période (jours)"
                        value={duration}
                    />
                )}
                
                {totalDays && (
                    <BoxInfo
                        title="Total jours de la période"
                        value={totalDays}
                    />
                )}

                {totalRatio && (
                    <BoxInfo
                        title="Ratio de jours"
                        value={totalRatio}
                        category='percent'
                    />
                )}

                {firstPayment && (
                    <BoxInfo
                        title="1ère Rente (en €)"
                        value={firstPayment}
                        category='currency'
                        decimals={2}
                    />
                
                )}

            </Grid>
        )
    }

    async function validateInvestment() {

        setIsLoading(true);
        var sendData = {
            category: CATEGORY_INVESTMENT,
            status: STATUS_REQUESTED,
            valueEur: parseFloat(amount), 
            accountID: account.accountID,
            accountName: account.name,

            dateSigned: getUTCDateFromISOStringDate(dateSigned),

            availableAssetID: selectedAvailableAsset.availableAssetID,
            assetName: selectedAvailableAsset.label,
            clientType: selectedAvailableAsset.clientType,
            investmentType: selectedAvailableAsset.investmentType,
            annualYield: parseFloat(selectedAvailableAsset.annualYield),
        }
        if(isReceived){
            sendData.dateReceived = getUTCDateFromISOStringDate(dateReceived);
            sendData.waitingPeriod = parseFloat(waitingPeriod);
            sendData.onDate = getUTCDateFromISOStringDate(onDate);    
        }
        
        if(endPeriod){
            sendData.endPeriod = getUTCDateFromISOStringDate(endPeriod);
            sendData.duration = duration;
            sendData.totalDays = totalDays;
            sendData.totalRatio = totalRatio;
            sendData.firstPayment = firstPayment;
        }

        if(operation){
            sendData.operationID = operation.operationID;
            await updateOperation(sendData);
        }
        else{
            await createOperation(sendData);
        }
        
        navigate(`/comptes/compte/${account.accountID}`)
    }
    
    if (isLoading) return <LoadingPage />

    return(
        <Wizard
            i18nStrings={{
                stepNumberLabel: stepNumber => `Etape ${stepNumber}`,
                collapsedStepsLabel: (stepNumber, stepsCount) => `Etape ${stepNumber} de ${stepsCount}`,
                skipToButtonLabel: (step, stepNumber) => `Aller à l'étape ${step.title}`,
                navigationAriaLabel: "Etape",
                // cancelButton: "Annuler",
                previousButton: "Précédent",
                nextButton: "Suivant",
                submitButton: "Valider",
                optional: "optional"
            }}
            onNavigate={({ detail }) =>
                setActiveStepIndex(detail.requestedStepIndex)
            }
            activeStepIndex={activeStepIndex}
            allowSkipTo
            steps={[Step1, StepValidation]}
            onSubmit={() => validateInvestment()}
        />
    )
}