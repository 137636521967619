
import { useLayoutEffect, useState } from 'react';
import { useCollection } from '@cloudscape-design/collection-hooks';

import { TableEmptyState, TableNoMatchState } from '../commons/common-components';
import { useColumnWidths } from '../commons/use-column-widths';
import { useLocalStorage } from '../commons/use-local-storage';

import TableSelectFilter from './customs/tableFiltered';
import CustomSelect from './customs/CustomSelect';
import { prepareSelectOptions } from '../utils/prepareSelectOptions';
import getAllKeys from '../utils/getAllKeys';
import consoleOnlyLocally from '../utils/consoleOnlyLocally';

import { StatusComponent } from './customs/table-select-filter-components';
import { Link, Box, Button } from '@cloudscape-design/components';
import { createTableSortLabelFn } from './customs/table';
import { toFormatCurrency, toFormatDate, toFormatPercent } from '../utils/toFormat';
import LoadingPage from '../loading/Loading';
import { STATUS_REQUESTED } from '../constants/global';
import { deleteOperation } from '../services/calls/operation';


export default function TableOperations({ operations, accountID = null }) {

  let [isLoading, setIsLoading] = useState(false);

  const allKeys = getAllKeys(operations);
  // const defaultContentDisplay = allKeys.map(key => ({
  //     id: key,
  //     visible: true,
  // }));
  const searchable_columns = allKeys;
  
  const rawColumns = [
    {
      id: 'accountName',
      header: 'Account',
      cell:  item  => item.accountName,
      sortingField: 'accountName',
    },
    {
      id: 'onDate',
      header: 'Date',
      cell: item => toFormatDate(item.onDate),
      sortingField: 'onDate',
    },
    {
      id: "valueEur",
      header: "Value",
      cell: item => toFormatCurrency(item.valueEur)
    },
    {
      id: 'category',
      header: 'Catégorie',
      cell: item => item.category,
      sortingField: 'category',
    },
    {
      id: 'assetName',
      header: 'Actif',
      cell: item => item.assetName,
      sortingField: 'assetName',
    },
    {
      id: "annualYield",
      header: "Taux Annuel",
      cell: item => toFormatPercent(item.annualYield)
    },
    {
      id: 'statut',
      header: 'Statut',
      cell: item => <StatusComponent status={item.status} />,
      sortingField: 'statut',
    },
    {
      id: 'operationID',
      header: 'Details',
      cell: item => <Link href={`/operations/operation/${item.operationID}`}>URL</Link>,
      sortingField: 'operationID',
      // sortingComparator: (item1, item2) => item1.id.substring(13, item2.length) - item2.id.substring(13, item1.length),
    },
    {
        id: 'delete',
        header: 'Suppression',
        cell: item => item.status === STATUS_REQUESTED && <div className="icon-container"><Button variant="inline-icon" iconName="remove" onClick={() => handleDeleteOperation(item)}/> </div>,
    },
  ];
  
  const COLUMN_DEFINITIONS = rawColumns.map(column => ({ ...column, ariaLabel: createTableSortLabelFn(column) }));

  const CONTENT_DISPLAY_OPTIONS = [
    { id: 'accountName', label: 'Account', visible: true },
    { id: 'onDate', label: 'Date', visible: true },
    { id: 'valueEur', label: 'Value', visible: true },
    { id: 'category', label: 'Catégorie', visible: true },
    { id: 'assetName', label: 'Actif', visible: true },
    { id: 'annualYield', label: 'Taux Annuel', visible: true },
    { id: 'statut', label: 'Statut', visible: true },
    { id: 'operationID', label: 'Details', visible: true },
    { id: 'delete', label: 'Suppression', visible: true },
  ];


  const [columnDefinitions, saveWidths] = useColumnWidths('React-TableSelectFilter-Widths', COLUMN_DEFINITIONS);
  const [preferences, setPreferences] = useLocalStorage('React-TableSelectFilter-Preferences', {
      pageSize: 30,
      contentDisplay: CONTENT_DISPLAY_OPTIONS,
      wrapLines: false,
      stripedRows: false,
      contentDensity: 'comfortable',
      custom: 'table',
  });

  const defaultCategory = { value: '0', label: 'Any Category' };
  const defaultStatus = { value: '0', label: 'Any Status' };

  const selectCategoryOptions = prepareSelectOptions('category', defaultCategory, operations);
  const selectStatusOptions = prepareSelectOptions('status', defaultStatus, operations);

  const [category, setCategory] = useState(defaultCategory);
  const [status, setStatus] = useState(defaultStatus);

  function matchesCategory(item, selectedCategory) {
      return selectedCategory.value === defaultCategory.value || item.category === selectedCategory.label;
  }
  
  function matchesStatus(item, selectedStatus) {
      return selectedStatus.value === defaultStatus.value || item.status === selectedStatus.label;
  }

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(operations, {
      filtering: {
          empty: <TableEmptyState resourceName="Instance" />,
          noMatch: <TableNoMatchState onClearFilter={clearFilter} />,
          filteringFunction: (item, filteringText) => {
              if (!matchesCategory(item, category)) {
                  return false;
              }
              if (!matchesStatus(item, status)) {
                  return false;
              }
              const filteringTextLowerCase = filteringText.toLowerCase();

              return searchable_columns.map(key => item[key]).some(
                  value => typeof value === 'string' && value.toLowerCase().indexOf(filteringTextLowerCase) > -1
              );
          },
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: { defaultState: { sortingColumn: columnDefinitions[0] } },
      selection: {},
  });

  useLayoutEffect(() => {
      collectionProps.ref.current?.scrollToTop();
  }, [collectionProps.ref, filterProps.filteringText, status, category]);

  function clearFilter() {
      actions.setFiltering('');
      setCategory(defaultCategory);
      setStatus(defaultStatus);
  }

  async function handleDeleteOperation(item) {

    setIsLoading(true);
  
    consoleOnlyLocally("item")
    consoleOnlyLocally(item.operationID)

    await deleteOperation({operationID: item.operationID});
    
    window.location.reload();
    setIsLoading(false);
  }

  consoleOnlyLocally("accountID: "+ accountID);

  if(isLoading) return <LoadingPage />;

  return (
      <TableSelectFilter
          collectionProps={collectionProps} 
          paginationProps={paginationProps}
          preferences={preferences}
          columnDefinitions={columnDefinitions}
          items={items}
          saveWidths={saveWidths}
          setPreferences={setPreferences}
          displayOptions={CONTENT_DISPLAY_OPTIONS}
          titleHeader="Liste des Operations"
          description="21yield"
          routeDestination={accountID ? `/operations/ajouter/${accountID}`:  "/operations/ajouter"}
      >
          <CustomSelect selectOptions={selectCategoryOptions} value={category} setValue={setCategory} name="Category" />
          <CustomSelect selectOptions={selectStatusOptions} value={status} setValue={setStatus} name="Statut" />
      </TableSelectFilter>
  );
}
