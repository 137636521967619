import { Grid } from "@cloudscape-design/components";
import CustomInput from "../customs/CustomInput";
import CustomInputDate from "../customs/CustomInputDate";


export default function CompanyInfoForm({ companyLegalName, setCompanyLegalName, registrationNumber, setRegistrationNumber, 
    registrationOffice, setRegistrationOffice, codeNaf, setCodeNaf, businessSector, setBusinessSector, 
    equity, setEquity, dateCreated, setDateCreated, hasSearchSiren}) {

    return(hasSearchSiren && (
    <Grid
        gridDefinition={[
            { colspan: { default: 12, xxs: 4}},
            { colspan: { default: 12, xxs: 4}},
            { colspan: { default: 12, xxs: 4}},
            { colspan: { default: 12, xxs: 2}},
            { colspan: { default: 12, xxs: 4}},
            { colspan: { default: 12, xxs: 3}},
            { colspan: { default: 12, xxs: 3}},
        ]}
    >
        <CustomInput
            label="Legal Name"
            placeholder={"Nom légal de l'entreprise"}
            value={companyLegalName}
            setValue={setCompanyLegalName}
        />
        <CustomInput
            label="SIREN"
            placeholder={"Numéro de SIREN"}
            value={registrationNumber}
            setValue={setRegistrationNumber}
        />
        <CustomInput
            label="Greffe"
            placeholder={"Greffe"}
            value={registrationOffice}
            setValue={setRegistrationOffice}
        />
        <CustomInput
            label="Code NAF"
            placeholder={"Code NAF"}
            value={codeNaf}
            setValue={setCodeNaf}
        />
        <CustomInput
            label="Business Sector"
            placeholder={"Secteur d'activité"}
            value={businessSector}
            setValue={setBusinessSector}
        />
        <CustomInput
            label="Equity"
            placeholder={"Capital"}
            value={equity}
            setValue={setEquity}
        />
        <CustomInputDate
            label="Date Created"
            value={dateCreated}
            setValue={setDateCreated}
        />
        {/* <CustomInput
            label="Date Created"
            placeholder={"Date de création"}
            value={dateCreated}
            setValue={setDateCreated}
        /> */}
    </Grid>)
    );
}