
import MainLayout from '../layout/MainLayout';
import { ContentLayout } from '@cloudscape-design/components';
import { useLoaderData } from 'react-router-dom';
import TableAccounts from '../components/tableAccounts';


export default function Accounts() {

    const { accounts, breadcrumbs } = useLoaderData();

    return (
        <MainLayout breadcrumbs={breadcrumbs} activeHref={"comptes"}>
            <ContentLayout>
                <TableAccounts accounts={accounts} />
            </ContentLayout>
        </MainLayout>
    );
}