import { 
  Box,
  Grid,
  Button
} from "@cloudscape-design/components";
import { useMemo, useState } from "react";
import { Autocomplete } from "@react-google-maps/api";
import countryList from "react-select-country-list";

import CustomInput from "../customs/CustomInput";
import consoleOnlyLocally from "../../utils/consoleOnlyLocally";


function AutocompleteComponent({ onSubmit }) {
  const [inputValue, setInputValue] = useState("");
  const [place, setPlace] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);

  const countryOptions = useMemo(() => countryList().getData(), []);

  const handlePlaceChanged = () => {
    if (autocomplete !== null) {
        const newPlace = autocomplete.getPlace();
        setPlace(newPlace);
      }
  };

  const validatePlace = () => {
    
    var address_components = place.address_components;
    consoleOnlyLocally(address_components);

    var hasComplement = address_components.length > 7 ? true : false;
    var startIndice = hasComplement ? 1 : 0;

    var hasNumber = parseInt(address_components[startIndice].long_name) ? true : false;
    consoleOnlyLocally(hasNumber);

    try {
      if(!hasNumber){
        var address = address_components[0].long_name;
        var city = address_components[1].long_name;
        var countryString = address_components[4].long_name
        var zipCode = address_components[5].long_name;
        var addressComplement = "";
      
      }
      else {

        if(hasComplement){
          var address = address_components[1].long_name + " " + address_components[2].long_name;
          var city = address_components[3].long_name;
          var countryString = address_components[6].long_name
          var zipCode = address_components[7].long_name;
          var addressComplement = address_components[0].long_name;
        }
        else {
            var address = address_components[0].long_name + " " + address_components[1].long_name;
            var city = address_components[2].long_name;
            var countryString = address_components[5].long_name
            var zipCode = address_components[6].long_name;
            var addressComplement = "";
        }
      }

      // Find in options, the country object that match the countryString as label
      var country = countryOptions.find((c) => c.label === countryString);
      
      onSubmit({ address, addressComplement, zipCode, city, country })
      setPlace(null);

    } catch (error) {
      consoleOnlyLocally(error);
      onSubmit({})
      setPlace(null);
    }

  }


  return (
    <Box>
      <Autocomplete
        onLoad={(autocompl) => {
          setAutocomplete(autocompl);
        }}
        onPlaceChanged={handlePlaceChanged}
      >
        <CustomInput
          label="Rechercher l'adresse avec l'API Google"
          placeholder="Entrez une adresse"
          value={inputValue}
          setValue={setInputValue}
        />
      </Autocomplete>
      {place && (
        <Grid 
          gridDefinition={[
            { colspan: { default: 12, xxs: 9}},
            { colspan: { default: 12, xxs: 3}},
          ]}
        >
          <div>
            <div>Resultat de la recherche :</div>
            <Box variant="h3">{place.formatted_address}</Box>
          </div>
          <Box margin={{ top: 'xl'}}>
            <Button variant="primary" onClick={validatePlace}>Valider</Button>
          </Box>
        </Grid>
      )}
    </Box>
  );
}

export default AutocompleteComponent;
