import { getAllAccounts } from "../services/calls/account";


export async function accountsLoader({ request }) {
  
  const { accounts } = await getAllAccounts();
  const breadcrumbs = [
    {
      text: 'Gestion des Comptes',
      href: '/',
    },
    {
      text: 'Comptes',
    },
  ];

    return { accounts, breadcrumbs };
  }
  