import { getAvailableAsset } from "../services/calls/available-asset";


export async function availableAssetLoader({ request, params }) {

    const availableAssetID = params.availableAssetID;
    const dataAvailableAsset = await getAvailableAsset({availableAssetID: availableAssetID});
    const availableAsset = dataAvailableAsset.availableAsset;

    const breadcrumbs = [
        {
            text: 'Gestion du Fonds',
            href: '/',
        },
        {
            text: 'Produit Financier: ' + availableAsset.label,
        },
  ];

    return { availableAsset, breadcrumbs };
  }
  