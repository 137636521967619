import { Container } from "@cloudscape-design/components";
import { useLoaderData } from "react-router-dom";
import AvailableAssetForm from "../components/AvailableAssetForm";
import MainLayout from "../layout/MainLayout";


export default function AvailableAsset() {

    const { availableAsset, breadcrumbs } = useLoaderData();

    return (
        <MainLayout  breadcrumbs={breadcrumbs}>
            <Container>

                <AvailableAssetForm availableAsset={availableAsset} />

            </Container>
        </MainLayout>
    );
}