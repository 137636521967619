import { useEffect, useState } from 'react';

import LoadingPage from '../../loading/Loading';
import { getAllAssetsInAccount } from '../../services/calls/asset';
import consoleOnlyLocally from '../../utils/consoleOnlyLocally';
import { getCashouts } from '../../services/calls/operation';
import AddCashoutComponent from './addCashoutComponent';
import { getUTCDateFromISOStringDate } from '../../utils/dateUtils';


export default function AddCashout({ account }) {

    let [assets, setAssets] = useState([]);
    let [cashouts, setCashouts] = useState([])

    // Initial Load all Assets from the Account (run on initial page load)
    async function initialLoad() {

        var cashoutsData = await getCashouts({accountID: account.accountID});
        var theCashouts = cashoutsData.cashouts;
        consoleOnlyLocally(theCashouts);

        // sort the cashouts by onDate ascending
        theCashouts.sort((a, b) => {
            return getUTCDateFromISOStringDate(a.onDate) - getUTCDateFromISOStringDate(b.onDate);
        });
        setCashouts(theCashouts);

        var assetsData = await getAllAssetsInAccount({accountID: account.accountID});
        var theAssets = assetsData.assets;
        consoleOnlyLocally(theAssets);
        setAssets(theAssets);
    }
    useEffect(() => {
        initialLoad();
    }, []);

    // When Assets have been retrieved, display the page
    if(assets.length !== 0) return <AddCashoutComponent fromUser={"admin"} account={account} assets={assets} cashouts={cashouts} />
    
    // While loading, display a loading page
    return <LoadingPage />;
}