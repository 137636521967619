import consoleOnlyLocally from "../../utils/consoleOnlyLocally";
import { getCalltoAPI } from "../axiosCall";


export async function checkAuth() {

    consoleOnlyLocally("checkAuth");  
    var response = await getCalltoAPI("/check");
    return response
}
