import { Grid, SpaceBetween, Wizard } from '@cloudscape-design/components'
import { useEffect, useState } from 'react';
import { Button } from "antd";
import { useNavigate } from 'react-router-dom';

import consoleOnlyLocally from '../../utils/consoleOnlyLocally';
import { getUTCDateFromISOStringDate, getUTCDateNow, setAsUTCString } from '../../utils/dateUtils';
import { calculateCompoundedValue, calculateLastPaymentInfo } from '../../utils/calculatePayout';
import { inputCurrencyFormatter, inputCurrencyParser } from '../../utils/formatters';
import { toFormatCurrency, toFormatDate } from '../../utils/toFormat';

import { CATEGORY_CASHOUT, OPTION_DISTRIBUTION } from '../../constants/global_lists';
import { STATUS_REQUESTED } from '../../constants/global';

import LoadingPage from '../../loading/Loading';
import { createOperation } from '../../services/calls/operation';
import CustomSelect from '../customs/CustomSelect';
import ValueWithLabel from '../customs/ValueWithLabel';
import CustomInputNumber from '../customs/CustomInputNumber';
import CustomInputDate from '../customs/CustomInputDate';


export default function AddCashoutComponent({ fromUser, account, assets, cashouts }) {

    let navigate = useNavigate();
    let [isLoading, setIsLoading] = useState(true);

    let [selectedAsset, setSelectedAsset] = useState(assets[0]);
    
    let [amount, setAmount] = useState(0);
    let [maxAvailableWithdraw, setMaxAvailableWithdraw] = useState(0);

    // let availableWithdrawDates = getEndNextQuarters(10);
    let [withdrawDate, setWithdrawDate] = useState(getUTCDateNow().toISOString());

    const [ activeStepIndex, setActiveStepIndex ] = useState(0);

    // Initial run on page load
    async function initData() {      
        if(assets.length > 0){
            processChangeAsset(assets[0], cashouts);
        }
        setIsLoading(false);
    }
    useEffect(() => { initData(); }, []);
    
    if(!selectedAsset) return <div>No Asset</div>

    async function processChangeAsset(theAsset, allCashouts) {
        var endDate = getUTCDateFromISOStringDate(withdrawDate);
        var { beforeCashouts, afterCashouts } = selectCashoutsForAsset(theAsset, allCashouts, endDate);
        processCalculation(beforeCashouts, afterCashouts, theAsset, endDate);
    }
    
    function processCalculation(beforeCashouts, afterCashouts, asset, endDate) {

        let balance = asset.balance;
        consoleOnlyLocally(balance);

        // if the asset is a distribution, decrease the balance by the cashouts value
        if(asset.investmentType.value === OPTION_DISTRIBUTION.value) {
            
            // for each cashout, decrease the resulting balance by the cashout value
            beforeCashouts.forEach(cashout => {
                balance -= cashout.valueEur;
            });
            afterCashouts.forEach(cashout => {
                balance -= cashout.valueEur;
            });
        }

        //  if the asset is a capitalisation, calculate the compounded value between each cashout and then decrease the cashout value
        else{
            consoleOnlyLocally("before cashouts");
            consoleOnlyLocally(beforeCashouts);
            var prevDate = getUTCDateNow();
            beforeCashouts.forEach(cashout => {
                balance = calculateCompoundedValue(prevDate, getUTCDateFromISOStringDate(cashout.onDate), asset.annualYield, balance);
                prevDate = getUTCDateFromISOStringDate(cashout.onDate);
                balance -= cashout.valueEur;
            })

            // calculate the compounded value between the last cashout and the end date
            balance = calculateCompoundedValue(prevDate, endDate, asset.annualYield, balance);
            prevDate = endDate;

            consoleOnlyLocally("after cashouts");
            consoleOnlyLocally(afterCashouts);

            // decrease the balance by the cashouts after the endDate
            afterCashouts.forEach(cashout => {
                balance -= cashout.valueEur;
            });
        }
        balance = Math.max(0, balance);
        setMaxAvailableWithdraw(balance);
        return balance;
    }

    function selectCashoutsForAsset(theAsset, allCashouts, endDate) {
        var filteredCashouts = allCashouts.filter(cashout => {
            return cashout.availableAssetID === theAsset.availableAssetID && cashout.status === STATUS_REQUESTED;
        });

        // select the cashouts that are before and after the endDate
        var beforeCashouts = [];
        var afterCashouts = [];
        filteredCashouts.forEach(cashout => {
            var cashoutDate = getUTCDateFromISOStringDate(cashout.onDate);
            if(cashoutDate <= endDate) {
                beforeCashouts.push(cashout);
            }
            else {
                afterCashouts.push(cashout);
            }
        });

        consoleOnlyLocally({beforeCashouts, afterCashouts})
        
        return { beforeCashouts, afterCashouts } ;
    }

    function handleChangeDate(date){

        setIsLoading(true);

        setWithdrawDate(date);
        var endDate = getUTCDateFromISOStringDate(date);
        consoleOnlyLocally(endDate);

        var { beforeCashouts, afterCashouts } = selectCashoutsForAsset(selectedAsset, cashouts, endDate);
        processCalculation(beforeCashouts, afterCashouts, selectedAsset, endDate);

        setIsLoading(false);
    }

    async function handledChangeAsset(asset) {

        setIsLoading(true);
        setSelectedAsset(asset);

        processChangeAsset(asset, cashouts);

        setIsLoading(false);
    }

    async function handleChangeAmount(detail) {

        var value = parseFloat(detail);
        if(value > maxAvailableWithdraw) {
            value = maxAvailableWithdraw;
        }
        setAmount(value);
    }

    function handleClicMax() {
        setAmount(maxAvailableWithdraw);
    }

    async function handleSubmit() {

        setIsLoading(true);

        var data = {
            category: CATEGORY_CASHOUT,
            status: STATUS_REQUESTED,
            valueEur: parseFloat(amount), 
            accountID: account.accountID,
            accountName: account.name,
            askedByUserID: fromUser,
            onDate: setAsUTCString(withdrawDate),

            availableAssetID: selectedAsset.availableAssetID,
            assetName: selectedAsset.label,
            clientType: selectedAsset.clientType,
            investmentType: selectedAsset.investmentType,
            annualYield: parseFloat(selectedAsset.annualYield)

        }   
        // Add the last payment info if it is a DISTRIBUTION
        if(selectedAsset.investmentType.value === OPTION_DISTRIBUTION.value){

            const { duration, totalDays, totalRatio, lastPayment } = calculateLastPaymentInfo(getUTCDateFromISOStringDate(withdrawDate), parseFloat(amount), selectedAsset.annualYield); 
            data = {
                ...data,
                duration: duration,
                totalDays: totalDays,
                totalRatio: totalRatio,
                lastPayment: lastPayment
            }
        }

        await createOperation(data);

        navigate(`/comptes/compte/${account.accountID}`)
    }   

    if (isLoading) return <LoadingPage />

    const Step1 = {
        title: "Retrait",
        description: "Planifier les caractéristiques de votre retrait",
        content: (
            <Grid
                gridDefinition={[
                    {colspan: {default: 12, xs: 5}},
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 4}},
                ]}
            >   
                <CustomSelect
                    selectOptions={assets}
                    value={selectedAsset}
                    setValue={handledChangeAsset}
                    name="Choisir un Actif"
                />
                <CustomInputDate
                    label="Date du Retrait"
                    value={withdrawDate}
                    setValue={handleChangeDate}
                />

                <div>
                    <SpaceBetween size="s">
                        
                        <div style={{display: 'flex', alignItems: 'end'}}>
                            <CustomInputNumber
                                defaultValue={0}
                                minValue={0}
                                name="Montant du Retrait"
                                value={amount}
                                setValue={handleChangeAmount}
                                formatter={(value) => inputCurrencyFormatter(value)}
                                parser={value => inputCurrencyParser(value)}
                            />
                            <Button onClick={handleClicMax}>Max</Button>
                        </div>

                    </SpaceBetween> 
                </div>

            </Grid>
        )
    }

    const StepValidation = {
        title: "Validation",
        description: "Vérifiez les informations saisies",
        content: (
            <Grid
                gridDefinition={[
                    {colspan: {default: 12, xs: 5}},
                    {colspan: {default: 12, xs: 3}},
                    {colspan: {default: 12, xs: 3}},
                ]}
            >
                <ValueWithLabel label="Produit Financier">
                    <div className='value-second'>{selectedAsset.label}</div>
                </ValueWithLabel>

                <ValueWithLabel label="Montant du Retrait">
                    <div className='value-second'>{toFormatCurrency(parseFloat(amount))}</div>
                </ValueWithLabel>

                <ValueWithLabel label="Date du Retrait">
                    <div className='value-second'>{toFormatDate(withdrawDate)}</div>
                </ValueWithLabel>
                {/* <BoxInfo
                    title="Date du Retrait"
                    value={withdrawDate.lastDay.toISOString().split('T')[0]}
                    category='date'
                /> */}

            </Grid>
        )
    }

    return(
        <Wizard
            i18nStrings={{
                stepNumberLabel: stepNumber => `Etape ${stepNumber}`,
                collapsedStepsLabel: (stepNumber, stepsCount) => `Etape ${stepNumber} de ${stepsCount}`,
                skipToButtonLabel: (step, stepNumber) => `Aller à l'étape ${step.title}`,
                navigationAriaLabel: "Etape",
                // cancelButton: "Annuler",
                previousButton: "Précédent",
                nextButton: "Suivant",
                submitButton: "Planifier le retrait",
                optional: "optional"
            }}
            onNavigate={({ detail }) =>
                setActiveStepIndex(detail.requestedStepIndex)
            }
            activeStepIndex={activeStepIndex}
            allowSkipTo
            steps={[Step1, StepValidation]}
            onSubmit={() => handleSubmit()}
            // onCancel={() => console.log("Cancel")}
        />
    )
}