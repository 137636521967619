import { postCalltoAPI, getCalltoAPI } from "../axiosCall";


// Method GET
export async function getInfo21yield() {
    
  var response = await getCalltoAPI( "/get_info_21yield");
  return response;
}

// Method POST
export async function createInfo21yield(sendData) {
    
    var response = await postCalltoAPI( "/create_info_21yield", sendData);
    return response;
}


export async function updateInfo21yield(sendData) {
    
    var response = await postCalltoAPI( "/update_info_21yield", sendData);
    return response;
}
