import { getInfo21yield } from "../services/calls/21yield";
import { getAllFintechs } from "../services/calls/fintech";


export async function addFintechLoader({ request }) {

  var dataFintechs = await getAllFintechs();
  const fintechs = dataFintechs.fintechs;
  
  var dataInfo21yield = await getInfo21yield();
  var info21yield = dataInfo21yield.info21yield;
  
  
  const breadcrumbs = [
    {
        text: 'Gestion du Fonds',
        href: '/',
    },
    {
        text: 'Performance',
        href: '/performance',
    },
    {
        text: 'Ajouter une Fintech',
    },
  ];

  return { fintechs, info21yield, breadcrumbs };
}
  