
import {
  CollectionPreferences
} from '@cloudscape-design/components';

const CONTENT_DISPLAY_OPTIONS = [
  { id: 'id', label: 'ID', alwaysVisible: true },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 Lignes' },
  { value: 30, label: '30 Lignes' },
  { value: 50, label: '50 Lignes' },
  { value: 100, label: '100 Lignes' },
  { value: 200, label: '200 Lignes' },
];

export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  contentDisplayOptions = CONTENT_DISPLAY_OPTIONS,
}) => (
  <CollectionPreferences
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    confirmLabel="Valider"
    cancelLabel="Annuler"
    pageSizePreference={{ options: pageSizeOptions }}
    // wrapLinesPreference={{}}
    // stripedRowsPreference={{}}
    // contentDensityPreference={{}}
    contentDisplayPreference={{ options: contentDisplayOptions }}
    // stickyColumnsPreference={{
    //   firstColumns: {
    //     title: 'Stick first column(s)',
    //     description: 'Keep the first column(s) visible while horizontally scrolling the table content.',
    //     options: [
    //       { label: 'None', value: 0 },
    //       { label: 'First column', value: 1 },
    //       { label: 'First two columns', value: 2 },
    //     ],
    //   },
    //   lastColumns: {
    //     title: 'Stick last column',
    //     description: 'Keep the last column visible while horizontally scrolling the table content.',
    //     options: [
    //       { label: 'None', value: 0 },
    //       { label: 'Last column', value: 1 },
    //     ],
    //   },
    // }}
  />
);