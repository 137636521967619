import { postCalltoAPI, getCalltoAPI } from "../axiosCall";


// Method GET
export async function getAllAvailableAssets(sendData) {

    var response = await getCalltoAPI( "/get_all_available_assets", sendData);
    return response;
}


// Method POST
export async function createAvailableAsset(sendData) {
    
    var response = await postCalltoAPI( "/create_available_asset", sendData);
    return response;
}

export async function updateAvailableAsset(sendData) {
    
    var response = await postCalltoAPI( "/update_available_asset", sendData);
    return response;
}

export async function getCategoriesAvailableAssets(sendData) {

    var response = await postCalltoAPI( "/get_categories_available_assets", sendData);
    return response;
}

export async function getAllAvailableAssetsForAccount(sendData) {

    var response = await postCalltoAPI( "/get_all_available_assets_for_account", sendData);
    return response;
}
  
export async function getAvailableAsset(sendData) {
    
    var response = await postCalltoAPI( "/get_available_asset", sendData);
    return response;
}