import { postCalltoAPI, getCalltoAPI } from "../axiosCall";


// Method GET
export async function getAllAccounts() {

  var response = await getCalltoAPI( "/get_all_accounts");
  return response;
}


// Method POST
export async function getAllOnAccount(sendData) {
    
  var response = await postCalltoAPI( "/get_all_on_account", sendData);
  return response;
}

export async function createAccount(sendData) {
    
    var response = await postCalltoAPI( "/create_account", sendData);
    return response;
}

export async function getAccount(sendData) {
    
  var response = await postCalltoAPI( "/get_account", sendData);
  return response;
}

export async function updateAccount(sendData) {
    
    var response = await postCalltoAPI( "/update_account", sendData);
    return response;
}
